import React, { useRef } from 'react'
import Classes from './SectionE.module.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function SectionE() {
    const sectionRef = useRef(null); // Reference to the section

    return (
        <section id="features" ref={sectionRef} className={Classes.section}>
            <div className={Classes.card} >
                <h1 className={Classes.subheading}> Unmatched efficiency and performance. </h1>
                <div className={Classes.boxesList} >
                    <div className={Classes.featureCard} style={{ animationDelay: `${1 * 0.2}s` }}>
                        <div className={Classes.cardBg} ></div>
                        <div className={Classes.miniBox} >
                            <LazyLoadImage
                                effect="opacity" src='/icons/clock-circle-white.png' alt='brush icon' />
                        </div>
                        <h5 className={Classes.boxTitle} > Effortless Screening <br/> with AI </h5>
                        <h5 className={Classes.boxText} > Leverage custom AI models to review pitch decks instantly. No need for time-consuming manual reviews—let our platform handle the heavy lifting. </h5>
                    </div>
                    <div className={Classes.featureCard} style={{ animationDelay: `${1 * 0.2}s` }}>
                        <div className={Classes.cardBg} ></div>
                        <div className={Classes.miniBox} >
                            <LazyLoadImage
                                effect="opacity" src='/icons/unmatched2.png' alt='brush icon' />
                        </div>
                        <h5 className={Classes.boxTitle} > No Large Data Sets <br/> Required</h5>
                        <h5 className={Classes.boxText} > Only basic company info and a pitch deck are required, while competitors rely on lengthy forms. This streamlined process saves time for everyone. </h5>
                    </div>
                    <div className={Classes.featureCard} style={{ animationDelay: `${1 * 0.2}s` }}>
                        <div className={Classes.cardBg} ></div>
                        <div className={Classes.miniBox} >
                            <LazyLoadImage
                                effect="opacity" src='/icons/unmatched3.png' alt='brush icon' />
                        </div>
                        <h5 className={Classes.boxTitle} > Comprehensive Deck <br/> Analysis</h5>
                        <h5 className={Classes.boxText} > Our custom VC-trained AI delivers comprehensive analysis using insights from over 20,000 pitch decks and expert guidance. </h5>
                    </div>
                    <div className={Classes.featureCard} style={{ animationDelay: `${1 * 0.2}s` }}>
                        <div className={Classes.cardBg} ></div>
                        <div className={Classes.miniBox} >
                            <LazyLoadImage
                                effect="opacity" src='/icons/dollar-symbol-white.png' alt='brush icon' />
                        </div>
                        <h5 className={Classes.boxTitle} > Cost-Effective <br/> Solution </h5>
                        <h5 className={Classes.boxText} > Save money by reducing the need for additional staff or resources. Evala.ai automates the screening process, making it both affordable and efficient. </h5>
                    </div>
                </div>

            </div>
        </section>
    )
}
