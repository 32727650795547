import React, { useState, useEffect } from "react";
import Classes from './LiveDemo.module.css';
import SummaryPopup from "./SummaryPopup";
import PageSelctorComp from "./PageSelctorComp";
import MobileVersionPopup from "../../Global-Components/MobileVersionPopup/MobileVersionPopup";
import Loading from "../../Global-Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import Color from 'color';

const convertToRgba = (colorInput, opacity) => {
    try {
        const color = Color(colorInput);
        return color.alpha(opacity).rgb().string();
    } catch (error) {
        return `rgba(0, 0, 0, ${opacity})`;
    }
};

const styles = {
    fontFamily: "Manrope, sans-serif",
    fontSize: "16px",


    color: "black",
    whiteSpace: "pre-wrap", // allow wrapping of long lines
    wordWrap: "break-word", // break long words

};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto", // Adjust width as needed
    maxWidth: "70vw", // Maximum width of the modal is 80% of the viewport width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enable scrolling if content is too long
    maxHeight: "65vh", // Maximum height is 80% of the viewport height
    // margin: "5vh", // Adds margin to the top & bottom of the modal (5% of the viewport height)
    borderRadius: "15px",
    outline: 'none',
};

function LiveDemo() {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    onBoarding.color = '#0262DF'
    onBoarding.logo = '/logoblue.png'
    const Navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [openSummary, setopenSummary] = useState(false);

    const handleCloseSummary = () => {
        setopenSummary(false);
    }

    const [Deck, setDeck] = useState(
        [
            {
                name: 'Deck 1',
                imageUrl: '/demo/Uber-01.jpg',
                feedback: `Your first slide effectively showcases your logo and slogan. The minimalist design ensures that the core branding elements are front and center, which is crucial for making a memorable first impression.`,
                suggestions: `Consider adding a subtle animation or a dynamic background to make the slide more engaging. Additionally, a brief tagline that communicates your unique value proposition could enhance the impact of your branding.`,
            },
            {
                name: 'Deck 2',
                imageUrl: '/demo/Uber-02.jpg',
                feedback: `The current logo design effectively uses minimalistic elements and a clear color palette which enhances brand recognition.`,
                suggestions: `Consider incorporating a subtle element that reflects the company's industry or values to add depth to the design. Additionally, testing the logo in different sizes and formats to ensure its versatility would be beneficial.`,
            },
            {
                name: 'Deck 3',
                imageUrl: '/demo/Uber-03.jpg',
                feedback: `The slide is currently empty, which indicates a focus on the logo design. It is crucial to maintain a minimalist approach while ensuring the logo is visually appealing and effectively communicates the brand identity.`,
                suggestions: `Consider incorporating the company's core values or primary services into the logo design to make it more meaningful and representative of the brand. It's also recommended to use colors that align with the brand's palette to enhance recognition and consistency across all platforms. Additionally, choosing a clear and easily readable typography can improve the logo's visibility and impact.`,
            },
            {
                name: 'Deck 4',
                imageUrl: '/demo/Uber-04.jpg',
                feedback: `The slide effectively conveys the importance of a well-designed logo in brand identity. It would be beneficial to include examples of successful logos to provide clearer context and inspiration.`,
                suggestions: `Consider adding a visual component to the slide that showcases different logo design trends or styles. This can help in visually communicating the type of branding the company aims to achieve. Additionally, providing guidelines or criteria for what makes an effective logo could be useful for aligning the design with the company’s branding objectives.`,
            },
            {
                name: 'Deck 5',
                imageUrl: '/demo/Uber-05.jpg',
                feedback: `The absence of additional information or visuals directs complete attention to the logo, maximizing impact but missing an opportunity to communicate brand values or messages directly through supplementary text or design elements.`,
                suggestions: `Consider integrating a brief tagline or descriptor that aligns with the logo to enhance brand messaging. Additionally, incorporating subtle background colors or patterns that reflect the brand palette could increase visual appeal without detracting from the logo's prominence.`,
            },
            {
                name: 'Deck 6',
                imageUrl: '/demo/Uber-06.jpg',
                feedback: `The current slide effectively spotlights the logo, which is essential for brand recognition. However, without accompanying text or context, it might not fully convey the intended message or values of the brand to the audience.`,
                suggestions: `Consider adding a brief tagline or statement that encapsulates the brand's mission or vision alongside the logo. This will provide immediate context and enhance the logo’s impact. Additionally, ensure that the logo is displayed with high resolution and is visually centered to maintain professional aesthetics. Exploring the use of background colors that complement or contrast with the logo might also enhance its visibility and appeal.`,
            },
            {
                name: 'Deck 7',
                imageUrl: '/demo/Uber-07.jpg',
                feedback: `The slide effectively utilizes minimalism which can be appealing and memorable. However, the absence of additional information might leave viewers needing more context or detail about the brand or product.`,
                suggestions: `Consider adding a tagline or a subtle design element that communicates the brand’s core message or value proposition. This can enhance the logo's impact without compromising the design's simplicity. Additionally, incorporating a brief introduction or mission statement could provide valuable context and connect better with the audience. This balance will likely make the pitch more comprehensive and engaging.`,
            },
            {
                name: 'Deck 8',
                imageUrl: '/demo/Uber-08.jpg',
                feedback: `The logo’s simplicity makes it highly versatile and scalable, suitable for various applications including digital and print. However, more emphasis on distinctiveness might help in standing out more prominently in a competitive market.`,
                suggestions: `Consider incorporating an element that highlights a unique aspect of the brand or experiment with a slightly bolder color palette to increase visibility. Also, testing the logo in different contexts and against competitor visuals could provide further insights into its effectiveness.`,
            },
            {
                name: 'Deck 9',
                imageUrl: '/demo/Uber-09.jpg',
                feedback: `The logo is a critical aspect of branding and should effectively communicate the company's identity and values. A successful logo is distinctive, visually appealing, and reflective of the business's brand personality.`,
                suggestions: `Consider incorporating elements that represent the company’s industry and mission. Use colors and fonts that resonate with the target audience. A minimalist approach often works well, ensuring the logo is scalable and effective across various mediums and sizes. It's also beneficial to get feedback from a diverse group of stakeholders during the design process to ensure the logo meets its intended purpose and appeal.`,
            },
            {
                name: 'Deck 10',
                imageUrl: '/demo/Uber-10.jpg',
                feedback: `The slide appears to be intentionally left blank, possibly to emphasize the focus on a logo. This minimalistic approach can be effective if the logo itself carries significant brand recognition or impact. However, without additional context or descriptive elements, the slide may fail to communicate the intended message or brand values to the audience.`,
                suggestions: `Consider adding a brief tagline or a succinct descriptor that aligns with the logo. This can enhance the audience's understanding and retention of the brand's message or identity. Additionally, ensure the logo is visually clear and placed prominently to draw attention effectively.`,
            },
            {
                name: 'Deck 11',
                imageUrl: '/demo/Uber-11.jpg',
                feedback: `The logo design utilizes a clean and modern aesthetic, which appeals to the target demographic. The simplicity of the design ensures versatility across various media and applications. However, the logo might benefit from slight enhancements to increase its distinctiveness in a competitive market.`,
                suggestions: `Consider introducing a unique design element that differentiates the logo from competitors. This could be a subtle tweak in the color scheme or a creative integration of the brand's initials. Additionally, experimenting with typography to find a more unique font that still maintains readability could enhance the logo's impact. Conducting A/B testing with these variations could provide valuable insights into consumer preferences and the effectiveness of the logo.`,
            },
            {
                name: 'Deck 12',
                imageUrl: '/demo/Uber-12.jpg',
                feedback: `The logo effectively uses colors and design to evoke a sense of innovation and reliability which is essential for brand recognition. However, the complexity of the design might be challenging for smaller scales or when used in black and white formats.`,
                suggestions: `Consider simplifying the design to ensure it remains effective across various applications, including digital and print media. Additionally, testing the logo in different formats and sizes to ensure legibility and impact could enhance its versatility and effectiveness.`,
            },
            {
                name: 'Deck 13',
                imageUrl: '/demo/Uber-13.jpg',
                feedback: `The slide appears to be intentionally left blank to emphasize the importance of a logo design. This minimalistic approach suggests focusing solely on visual branding elements without additional textual or numerical information.`,
                suggestions: `Given the focus on the logo, it might be beneficial to explore various logo designs that effectively represent the brand identity. Consider conducting a survey or gathering feedback to determine how well potential logos resonate with your target audience. Additionally, ensure the logo is versatile enough to be effective across various platforms and scales well from large banners to small mobile screens. Keep the design simple yet memorable to make a lasting impression.`,
            },
        ]
    );

    const goToLogin = () => {
        Navigate('/login');
    }

    const goToHome = () => {
        Navigate('/');
    }

    const downloadPdf = () => {
        const link = document.createElement('a');
        link.href = 'https://';
        link.setAttribute('download', '');
        // link.target = '_blank'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const [pageIndex, setpageIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setloading(false);
        }, 2500);
    }, []);

    return (
        <div className={Classes.container} >
            <>
                {loading && <Loading />}
                <MobileVersionPopup />
                <div className={Classes.innerCon} >
                    <div className={Classes.leftCon} >
                        <div className={Classes.listCon} >
                            <div className={Classes.scroll1} >
                                <br />
                                <div className={Classes.logoCon} >
                                    <img className={Classes.logo1} onClick={goToHome} src={onBoarding.logo} />
                                    <img className={Classes.logo2} onClick={goToHome} src='/onBoarding/left-arrow.svg' />
                                </div>
                                {Deck.map((deck, index) => {
                                    return (<>
                                        <PageSelctorComp imageUrl={deck.imageUrl} key={index} index={index} pageIndex={pageIndex} setpageIndex={setpageIndex} />
                                    </>)
                                })
                                }
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className={Classes.con1} >
                        <div className={Classes.swiperOuter} >
                            <div className={Classes.swiperCon} >
                                <div className={Classes.swiperUnloadedCon} >
                                    <img src={Deck[pageIndex].imageUrl} className={Classes.swiperImg} alt={`Sample Page 2`} />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.boxesCon} >

                            <div className={Classes.feedbackCon} >
                                <div >
                                    <img src="/feedback.png" />
                                    <h3> Feedback </h3>
                                </div>
                                <p>
                                    <pre style={{
                                        ...styles,
                                        flex: "1",
                                        minWidth: "0",
                                        margin: '0',
                                    }}>
                                        {
                                            Deck[pageIndex].feedback
                                        }
                                    </pre>
                                </p>

                            </div>

                            <div className={Classes.suggestCon} >
                                <div >
                                    <img src="/icons/suggestion.png" />
                                    <h3> Suggestions </h3>
                                </div>
                                <p>
                                    <pre style={{
                                        ...styles,
                                        flex: "1",
                                        minWidth: "0",
                                        margin: '0',
                                    }}>
                                        {
                                            Deck[pageIndex].suggestions
                                        }
                                    </pre>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className={Classes.scoreBoxCon} >
                        <div className={Classes.scoreBox} >
                            <h3 className="fw700" >Scoring breakdown</h3>
                            <h5 onClick={() => { setopenSummary(true) }} style={{ color: onBoarding.color }} >Read full summary {`>`}</h5>
                            {openSummary &&
                                <SummaryPopup
                                    style={style}
                                    openSummary={openSummary}
                                    handleCloseSummary={handleCloseSummary}
                                />
                            }
                            <div className={Classes.padCon} >
                                <hr />
                                <div className={Classes.linesCon} >
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/deck.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Deck structure </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 78 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/market.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Market Opportunity </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle}> 85 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/marketing.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Marketing Sales </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 70 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/business.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Business model </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 84 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/competitive.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Competitive Landscape </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 74 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/team.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Team </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 75 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/traction.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Traction </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 68 </h4>
                                    </div>
                                </div>
                            </div>
                            <div className={`${Classes.overallCon} ${Classes.padCon}`} style={{ background: onBoarding.color }} >
                                <h4 className={Classes.miniTitle} > Overall deck score </h4>
                                <h4 className={Classes.miniTitle} >
                                    76.57
                                </h4>
                            </div>
                            {/* <div className={`${Classes.templateOuter} ${Classes.padCon} `} >
                                <div className={Classes.templateCon} style={{ background: convertToRgba(onBoarding.color, 0.05) }} >
                                    <img src="/icons/download.svg" alt="download" />
                                    <h4 className={Classes.download} >Download here</h4>
                                </div>
                            </div> */}
                            <div className={`${Classes.templateOuter} ${Classes.padCon} `} >
                                <div className={Classes.templateCon} style={{ background: convertToRgba(onBoarding.color, 0.05) }} >
                                    {/* <img src="/template.png" alt="Image" /> */}
                                    <div className={Classes.ideaImg} style={{ background: onBoarding.color }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 384 512"><path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z" /></svg>
                                    </div>
                                    <h4>Pitch deck template</h4>
                                    <h5>
                                        Download our ultimate pitch <br />
                                        deck template with over 125 <br />
                                        premium designed slides.
                                    </h5>
                                    <h4 className={Classes.download}  style={{ color: onBoarding.color }} >Download here</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}

export default LiveDemo;