import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import {
    Button,
    ListItem,
    ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from '../../Pages/Home-New/Components/Animations/AnimateTopToBottom';
import StripeCheckout from 'react-stripe-checkout';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import backend from '../../api';
import { setUserData } from "../../redux-store/auth/actions";
import axios from 'axios';

const STRIPE_PUBLISHABLE_KEY = "pk_live_51OpvvzErETeoVarWzeVWtLtgwQOOKUK5HQGhbtbouFXAy03F93yduaJGLsNzhnVS7FRSyyQo7SnIfypJoXrADcQY00tBoarr6w"

function UpgradePopup({ closeUpgradePopup, closeable }) {
    const [isLifetime, setisLifetime] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const onToken = (amount, description) => (token) => {
        // debugger
        const headers = {
            "Content-Type": "application/json",
        };
        backend
            .post(
                "/payment",
                { description, token: token, amount: amount },
                { headers: headers }
            )
            .then((response) => {
                alert("payment success");
                onPayment();
            })
            .catch((err) => {
                console.log("error: ", err);
                alert("payment failure", err);
            });
    };

    const onPayment = async () => {
        const UserToken = user?.token;
        const UserExists = user?.exists;
        const newRD = 15;
        const email = user.email;

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await axios.post(`https://pitchperfecterai-257a6e04dc34.herokuapp.com/create-checkout-session`, {
            subscription: "monthly",
            remainingDecks: newRD,
            email,
            userId: user._id,
        })

        const url = response.data.url;
        window.location.href = url;
    }

    const onPaymentLifeTime = async () => {
        const UserToken = user?.token;
        const UserExists = user?.exists;
        const newRD = 180;
        const email = user.email;

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await axios.post(`https://pitchperfecterai-257a6e04dc34.herokuapp.com/create-checkout-session`, {
            subscription: "lifetime",
            remainingDecks: newRD,
            email,
            userId: user._id,
            type: "lifetime"
        })

        const url = response.data.url;
        window.location.href = url;
    }

    const renderFeature = (included, text) => (
        <ListItem style={{ padding: "0.1rem" }}>
            <ListItemIcon>
                {included ? (
                    <CheckIcon style={{ color: '#0262DF', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
                ) : (
                    <ClearIcon style={{ color: '#0262DF', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
                )}
            </ListItemIcon>

            <p className={Classes.card1_p} >{text}</p>
        </ListItem>
    );

    const handleBgClick = () => {
        if (closeable) {
            closeUpgradePopup();
        }
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card} onClick={(event) => { handleCardClick(event) }} >
                {closeable &&
                    <div className={Classes.close} onClick={closeUpgradePopup} >
                        <img src='/icons/close.png' alt='close icon' />
                    </div>
                }
                <div className={Classes.header} >
                    <img src='/icons/upgrade-round.png' alt='upgrade-icon' />
                    <h2 className={Classes.planName}>Upgrade to premium</h2>
                    <h5>Unlock all features to perfect your deck!</h5>
                </div>
                <div className={Classes.flex} >
                    <div className={Classes.pricingBtns} >
                        <button onClick={() => setisLifetime(false)} className={!isLifetime ? Classes.activeButton : Classes.inactiveButton}>
                            Monthly
                        </button>
                        <button onClick={() => setisLifetime(true)} className={isLifetime ? Classes.activeButton : Classes.inactiveButton}>
                            Lifetime
                        </button>
                        {/* <img src='/save15.png' alt='save-15' className={Classes.saveImage} /> */}
                    </div>
                </div>
                {isLifetime ?
                    <h1 className={Classes.price} > $49.99 <span className={Classes.priceSuffix}>lifetime</span></h1>
                    :
                    <h1 className={Classes.price} > $9.99 <span className={Classes.priceSuffix}>per month</span></h1>
                }
                <ul className={Classes.featureList}>
                    {renderFeature(true, `15 uploads per month`)}
                    {renderFeature(true, "Slide-by-slide analysis")}
                    {renderFeature(true, "Comprehensive summary")}
                    {renderFeature(true, "Premium templates")}
                    {renderFeature(true, "Business chatbot")}
                </ul>
                {isLifetime ?
                    <button onClick={onPaymentLifeTime} className={Classes.premiumBtn} >Upgrade to premium</button> //Yearly Button
                    :
                    <button onClick={onPayment} className={Classes.premiumBtn} >Upgrade to premium</button> //Monthly Button
                }
            </div>
        </div>
    )
}

export default UpgradePopup
