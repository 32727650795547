import React, { useState } from 'react'
import Classes from './styles/style.module.css'
import { AnimateTopToBottom } from '../Home-New/Components/Animations/AnimateTopToBottom'

function DataSecurity() {

    return (
        <>
            <form className={Classes.hero} >
                <AnimateTopToBottom delay={0.3}>
                    <h1>Data Security</h1>
                </AnimateTopToBottom>
                <div className={Classes.form_con} >
                    <AnimateTopToBottom delay={0.2}>
                        <h5 className={Classes.major_title} >How Secure is my Data with evala.ai?</h5>
                        <br />
                        <h5>
                            At evala.ai, we are committed to providing a secure and reliable experience for our users.
                            We understand the importance of protecting your data and ensuring the privacy of your information.
                            Our platform is built upon industry-leading technologies which incorporate the latest security
                            best practices to safeguard your data.
                        </h5>
                        <br />
                    </AnimateTopToBottom>

                    <AnimateTopToBottom delay={0.2}>
                        <h5 className={Classes.mini_title}>1. Cloud Infrastructure </h5>
                        <br />
                        <h5>
                            We use multiple industry-leading cloud providers: Microsoft Azure, AWS, and MongoDB to host our data and serve website content. All of which have the highest and most comprehensive security compliance and certifications.
                        </h5>
                        <br />
                        <h5>
                            Our data center region is located within the US, and all of our data processors are SOC 2 Type II compliant. You can read about each provider:
                        </h5>
                        <ul>
                            <li> <h5>Microsoft Azure</h5> </li>
                            <li> <h5>AWS</h5> </li>
                            <li> <h5>MongoDB</h5> </li>
                        </ul>
                    </AnimateTopToBottom>

                    <AnimateTopToBottom delay={0.2}>
                        <h5 className={Classes.mini_title}>2. System Security </h5>
                        <br />
                        <h5>
                            All sensitive data transmitted between our servers and your browser is encrypted using SSL/TLS encryption. This ensures that your data remains secure and confidential during transmission. In addition, all sensitive data is encrypted at rest.
                        </h5>
                        <br />
                    </AnimateTopToBottom>

                    <AnimateTopToBottom delay={0.2}>
                        <h5 className={Classes.mini_title}>3. Payments </h5>
                        <br />
                        <h5>
                            We use Stripe to securely process payments. Stripe is a certified PCI Service Provider Level 1, the highest level of certification in the payments industry. <a href='https://docs.stripe.com/security' target='_blank' >Learn more here</a>.
                        </h5>
                        <br />
                    </AnimateTopToBottom>

                    <AnimateTopToBottom delay={0.2}>
                        <h5 className={Classes.major_title}>Large Language Model </h5>
                        <br />
                        <h5 className={Classes.mini_title}>4. Azure GPT Models </h5>
                        <br />
                        <h5>
                            At evala.ai, we utilize a customized LLM model from Microsoft Azure OpenAI, enhanced with our proprietary AI data processing algorithm to power our platform. Your data, your pitch decks, and your feedback:
                        </h5>
                        <br />
                        <ul>
                            <li><h5> Are NOT available to other customers. </h5></li>
                            <li><h5> Are NOT available to OpenAI. </h5></li>
                            <li><h5> Are NOT used to improve OpenAI models. </h5></li>
                            <li><h5> Are NOT used to improve any Microsoft or 3rd party products or services. </h5></li>
                            <li><h5> Your fine-tuned Azure OpenAI models are available exclusively for your use. </h5></li>
                        </ul>
                        <br />
                    </AnimateTopToBottom>

                </div>
            </form>
        </>
    )
}

export default DataSecurity
