// reducers.js
import { SET_CHATHISTORY_ID } from './actions';

const initialState = {
  chatId: '',
};

const chatHistoryIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATHISTORY_ID:
      return {
        ...state,
        chatId: action.payload,
      };
    default:
      return state;
  }
};

export default chatHistoryIdReducer;
