import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from './Global-Components/Menu';
import Footer from './Global-Components/Footer/Footer';
import Auth from './Pages/Auth/Auth';
import Signup from './Pages/Auth/Signup';

import ResponsiveDrawer from './Global-Components/Sidebar';
import Home from './Pages/Home/Home';
import PitchDeckGen from './Pages/Pitch-Deck-gen/Pitch-Deck-gen';
import PitchQuestions from './Pages/Pitch-Deck-gen/PitchQuestions';
import Inbox from "./Pages/Account/Inbox/Inbox";
import PitchAnalyzer from './Pages/Pitch-Analyzer/Pitch-Analyzer';
import PitchEditor from './Pages/Pitch-Editor/Pitch-Editor';
// import HomeNew from './Pages/Home-New/Home-New';
import HomeNew from './Pages/Home-Redesigned/Home-New';
import PitchDashboard from './Pages/Pitch-Dashboard/Pitch-Dashboard';
import ScreeningDashboard from './Pages/Screening-Dashboard/ScreeningDashboard';
import Setting from './Pages/Setting/Setting';
import PitchTemplates from './Pages/Pitch-Templates/Pitch-Templates';
import PitchPDFanalyzer from './Pages/Pitch-Analyzer/Pitch-Analyzer-pdf';
import Testing from './Pages/Pitch-Analyzer/Testing';
import Testing1 from './Pages/Pitch-Analyzer/Testing1';
import LoadingLineWithPercentage from './Pages/Pitch-Analyzer/Loader';
import ContactUs from './Pages/ContactUs/ContactUs';
import BookDemo from './Pages/BookDemo/BookDemo';
import OnBoarding from './Pages/OnBoarding/OnBoarding';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService/TermsOfService';
import SidebarForAnalyzer from './Global-Components/SidebarForAnalyzer';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import LiveDemo from './Pages/LiveDemo/LiveDemo';
import { Analytics } from "@vercel/analytics/react"

// On boarding imports
import PitchDashboard2 from './On-boarding/Pages/Pitch-Dashboard/Pitch-Dashboard';
import Auth2 from './On-boarding/Pages/Auth/Auth';
import ResponsiveDrawer2 from './On-boarding/Global-Components/Sidebar';
import Testing2 from './On-boarding/Pages/Pitch-Analyzer/Testing1';
import Blog from './Pages/Blog/Blog';


import './App.css'
import DataSecurity from './Pages/DataSecurity/DataSecurity';
import { AnimatePresence } from 'framer-motion';

const AnalyzerWithSideBar = (Component) => (
  <>
    <SidebarForAnalyzer component={<Component />} />
  </>
);

const RenderRouterWithSideBar = (Component) => (
  <>
    <ResponsiveDrawer component={<Component />} />
  </>
);

const RenderRouterWithSideBar2 = (Component) => (
  <>
    <ResponsiveDrawer2 component={<Component />} />
  </>
);

const RenderRouterWithMenuFooter = (Component) => (
  <>
    <Menu />
    <Component />
    <Footer />
  </>
);

const RenderRouterWithMenuOnly = (Component) => (
  <>
    <Menu />
    <Component />
  </>
);

const RenderRouterWithFooterOnly = (Component) => (
  <>
    <Component />
    <Footer />
  </>
);

const RenderRouterOnly = (Component) => (
  <>
    <Component />
  </>
);

const LoggedInRoutes = () => (
  <>
    <Routes>
      <Route path="/" element={RenderRouterWithSideBar(HomeNew)} />
      {/* <Route path="/chatbot/:id/" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/chatbot" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/pitchgenerator" element={RenderRouterWithMenuOnly(PitchDeckGen)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} /> */}
      {/* <Route path="/analyzer" element={AnalyzerWithSideBar(Testing1)} />
      <Route path="/analyzer/:id" element={AnalyzerWithSideBar(Testing1)} /> */}
      {/* <Route path="/pitcheditor" element={RenderRouterWithSideBar(PitchEditor)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} /> */}
      {/* <Route path="/dashboard" element={RenderRouterWithSideBar(PitchDashboard)} /> */}
      {/* <Route path="/templates" element={RenderRouterWithSideBar(PitchTemplates)} /> */}
      {/* <Route path="/testing" element={RenderRouterWithSideBar(Testing)} />
      <Route path="/testing1" element={RenderRouterWithSideBar(Testing1)} /> */}
      <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/contact-us" element={RenderRouterWithFooterOnly(ContactUs)} />
      <Route path="/book-demo" element={RenderRouterWithMenuFooter(BookDemo)} />
      <Route path="/privacy-policy" element={RenderRouterWithMenuFooter(PrivacyPolicy)} />
      <Route path="/data-security" element={RenderRouterWithMenuFooter(DataSecurity)} />
      <Route path="/terms-of-service" element={RenderRouterWithMenuFooter(TermsOfService)} />
      <Route path="/blog" element={<Blog />} />

      <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />

      {/* Company roouts */}
      {/* <Route path="/" element={RenderRouterWithSideBar(PitchDashboard)} />
      <Route path="/dashboard" element={RenderRouterWithSideBar(PitchDashboard)} />
      <Route path="/screening-dashboard" element={RenderRouterWithSideBar(ScreeningDashboard)} />
      <Route path="/setting" element={RenderRouterWithSideBar(Setting)} />
      <Route path="/analyzer" element={<Testing1 />} />
      <Route path="/analyzer/:id" element={<Testing1 />} />
       */}
      {/* OnBoardingLoggedInRoutes => company's user routes */}

      {/* Company Onboarding */}
      <Route path="/on-boarding" element={<OnBoarding />} />


      {/* Customer Routes */}
      {/* <Route path="/" element={<ResponsiveDrawer2 />} />
      <Route path="/dashboard" element={<ResponsiveDrawer2 />} />
      <Route path="/analyzer" element={<Testing2 />} />
      <Route path="/analyzer/:id" element={<Testing2 />} /> */}

    </Routes>
  </>
);

const NotLoggedInRoutes = () => (
  <>
    <Routes>
      <Route path="/" element={RenderRouterWithMenuFooter(HomeNew)} />

      {/* lOGIN FOR COMPANY ADMIN */}
      <Route path="/login" element={<Auth />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/blog" element={<Blog />} />




      {/* LOGIN FOR USER */}
      {/* <Route path="/login" element={<Auth2 />} /> */}


      <Route path="/createpassword/:id" element={<ForgotPassword />} />
      <Route path="/pitchgenerator" element={RenderRouterWithMenuOnly(PitchDeckGen)} />
      <Route path="/contact-us" element={RenderRouterWithMenuFooter(ContactUs)} />
      <Route path="/book-demo" element={RenderRouterWithMenuFooter(BookDemo)} />
      <Route path="/privacy-policy" element={RenderRouterWithMenuFooter(PrivacyPolicy)} />
      <Route path="/data-security" element={RenderRouterWithMenuFooter(DataSecurity)} />
      <Route path="/terms-of-service" element={RenderRouterWithMenuFooter(TermsOfService)} />
      <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/demo" element={RenderRouterOnly(LiveDemo)} />
      <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />
      {/* OnBoardingNotLoggedInRoutes */}

      <Route path="/on-boarding" element={<OnBoarding />} />

    </Routes>
  </>
);

const OnBoardingNotLoggedInRoutes = () => (
  <>
    <Routes>
      <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />
    </Routes>
  </>
);

function AppRoutes() {
  const { user } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      {user ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
      {/* <OnBoardingNotLoggedInRoutes /> */}
      <Analytics />
    </BrowserRouter>
  );
}

export default AppRoutes;
