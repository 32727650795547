import React, { useState, useRef } from 'react';
import Classes from './styles/style.module.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { motion, easeOut } from 'framer-motion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../redux-store';
import axios from 'axios';
import backend from '../../api';
import Loading from '../../Global-Components/Loading/Loading';
import { Checkbox } from '@mui/material';

export default function OnBoarding() {
    const { user } = useSelector((state) => state.auth);
    const [imageURL, setImageURL] = useState([]);
    const [brochureLoading, setBrochureLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const userDataStringLocal = localStorage.getItem('user');
    const userData = JSON.parse(userDataStringLocal);
    const userId = userData._id;

    const navigateTo = useNavigate();
    const dispatch = useDispatch();

    const [companyName, setcompanyName] = useState('');
    const [companyWebsite, setcompanyWebsite] = useState('');
    const [scoringCriteria, setscoringCriteria] = useState([
        { name: 'deckStructure', status: false },
        { name: 'marketOpportunity', status: false },
        { name: 'marketingSales', status: false },
        { name: 'businessModel', status: false },
        { name: 'competitiveLandscape', status: false },
        { name: 'team', status: false },
        { name: 'traction', status: false }
    ]);
    const [colorScheme, setcolorScheme] = useState('#000000');
    const [image, setImage] = React.useState(null);

    const [alreadyExistError, setalreadyExistError] = useState(false);

    const uploadMedia = async (files, name) => {

        setBrochureLoading(true);
        try {
            const formData = new FormData();
            Array.from(files).forEach((file) => formData.append("files", file));
            console.log(formData)
            const response = await axios.post("https://evala-aws-server-f7f37b27f032.herokuapp.com/api/deck/upload", formData)
            console.log(response)

            if (name === "uploadBrochure") {
                const updatedFiles = response?.data?.data.map((d) => ({ ...d, key: d.name }));
                setImageURL(prev => [...prev, ...updatedFiles]);
            }
            // toast.success("Files uploaded successfully");
        } catch (error) {
            alert(error.response?.data?.message || error.message || "An error occurred");
        } finally {
            setBrochureLoading(false);
            // setopenConfirmPopup(true);
            //   setTimeout(() => {
            //     if (analyze.current) {
            //       analyze.current.click();
            //     }
            //   }, 500);
        }
    };


    const handleInputChange = (e) => {
        const files = e.target.files;
        const name = e.target.name;
        handleFileChange(files, name);
    };

    const handleFileChange = (files, name) => {

        uploadMedia(files, name);

    }

    // const userId = user?._id
    // console.log(user)

    // console.log(userId)
    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateUser()

    };


    const logo = imageURL[0]?.imgurl
    // const userData = JSON.stringify(user);
    const handleLogout = async () => {
        localStorage.clear();
        await dispatch(authActions.logout());
    };

    const newName = companyName.toLowerCase().replace(/\s+/g, '');
    console.log(newName)

    const UpdateUser = async () => {
        const body = {
            userId: userId,
            companyName: newName,
            companyWebsite: companyWebsite,
            scoringCriteria: JSON.stringify(scoringCriteria),
            color: colorScheme,
            logo: logo
        }
        try {
            setLoading(true)
            const response = await backend.post(`/onboarding/`, body);

            if (response?.data && response.status === 200) {
                const updateUser = await backend.put(`/api/user/${userId}`, {companyId: response.data._id})
                console.log(updateUser)

                if (updateUser?.data?.data) {
                    const userDataString = JSON.stringify(updateUser.data.data); // Stringify the object
                    const userDataEncoded = encodeURIComponent(userDataString);

                    // handleLogout();

                    window.location.href = `https://${newName}.evala.ai?userData=${userDataEncoded}`;
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setalreadyExistError(true);
            setTimeout(() => {
                setalreadyExistError(false);
            }, 30000);
            setLoading(false)
            if (error.response && error.response.status === 400) {
                alert('Company name already exists');
            } else {
                alert('An unexpected error occurred. Please try again.');
            }
            
        } finally {
            setLoading(false)
        }
    };

    const handleImage = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setImageURL(URL.createObjectURL(file));
    };

    const imageInputRef = useRef(null);

    const handleClick = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    };

    const handleCheckboxChange = (index) => {
        setscoringCriteria(prevState => {
            const newState = [...prevState];
            newState[index].status = !newState[index].status;
            return newState;
        });
    };



    return (
        <>
            <div className={Classes.backgroundImage} >
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        duration: 0.3,
                        ease: easeOut,
                        delay: 1,
                    }}
                >
                    <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
                        <div>
                            <a className={Classes.logoLink} href="/">
                                <img className={Classes.logoImage} src='/Blue.png' alt="Logo" />
                            </a>
                        </div>
                        <nav className={Classes.navMenu}>

                        </nav>
                        <div>
                            <a href="/">
                                <h5 className={Classes.goBackTxt} >Go back to evala.ai</h5>
                            </a>
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        duration: 0.3,
                        ease: easeOut,
                        delay: 1,
                    }}
                >
                    <form onSubmit={handleSubmit} className={`${Classes.section}`}>
                        <div className={Classes.sectionInner} >
                            <div className={Classes.boxStyles}>
                                <h5 className={Classes.miniTitle} >Customize your screening tool</h5>
                                <p className={Classes.subtitle}
                                    style={{ fontWeight: '600', color: '#6E6E6E' }} >
                                    You can update any of this information anytime in your admin settings.
                                </p>
                                <br />
                                <div className={Classes.passwordCon} >
                                    <input
                                        onChange={(e) => setcompanyName(e.target.value)}
                                        type="text"
                                        placeholder="Enter your company name"
                                        value={companyName}
                                        required
                                    />
                                </div>
                                <div className={Classes.passwordCon} >
                                    <input
                                        onChange={(e) => setcompanyWebsite(e.target.value)}
                                        type="text"
                                        placeholder="Enter your company website"
                                        value={companyWebsite}
                                        required
                                    />
                                </div>
                                <div className={Classes.accordionCon} >
                                    <Accordion className={Classes.accordion}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                            <Typography className={Classes.accrdionText} >Choose deck scoring criteria</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ textAlign: "left" }}>
                                            <div className={Classes.list} >
                                                {scoringCriteria.map((criteria, index) => (
                                                    <div className={Classes.listItem} key={index}>
                                                        <label htmlFor={index}>
                                                            <h5 className={Classes.accrdionText}>{criteria.name.split(/(?=[A-Z])/).join(' ')}</h5>
                                                        </label>
                                                        <Checkbox
                                                            id={index}
                                                            checked={criteria.status}
                                                            onChange={() => handleCheckboxChange(index)}
                                                            sx={{
                                                                color: '#0262DF',
                                                                padding: '5px',
                                                                '&.Mui-checked': {
                                                                    color: '#0262DF',
                                                                },
                                                                '& .MuiSvgIcon-root': { fontSize: 17 }
                                                            }}
                                                        />
                                                        {/* <input
                                                            type='checkbox'
                                                            id={index}
                                                            checked={criteria.status}
                                                            onChange={() => handleCheckboxChange(index)}
                                                        /> */}
                                                    </div>
                                                ))}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className={Classes.accordionCon} >
                                    <Accordion className={Classes.accordion}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                            <Typography className={Classes.accrdionText} >Choose color scheme</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ textAlign: "left" }}>
                                            <div className={Classes.list} >
                                                <div className={Classes.textAlign} >
                                                    <input
                                                        type='color'
                                                        value={colorScheme}
                                                        onChange={(e) => { setcolorScheme(e.target.value) }}
                                                    />
                                                    <input
                                                        type='text'
                                                        value={colorScheme}
                                                        onChange={(e) => { setcolorScheme(e.target.value) }}
                                                        className={Classes.colorSchemeTextInput}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <h5 className={Classes.uploadText} > Upload logo </h5>
                                <div className={Classes.uploadCon} onClick={handleClick} >
                                    <input
                                        ref={imageInputRef}
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        onChange={handleInputChange}
                                        name="uploadBrochure"
                                        id="formFile"
                                    />
                                    <img src='/icons/fileIcon.png' />
                                </div>
                                <h5 className={Classes.transparentText} > Transparent PNG logo </h5>
                                {brochureLoading ? <CircularProgress /> : null}
                                {imageURL[0] && (
                                    <img
                                        style={{ height: "3rem", width: '3rem', margin: "3px", borderRadius: "6px" }}
                                        src={imageURL[0].imgurl}
                                        alt="Uploaded Image"
                                    />
                                )}
                                {alreadyExistError && <h5 className={Classes.error} > User already exist or bad request. </h5>}
                            </div>
                        </div>
                        <footer >
                            <button type="submit" >
                                <h5> {loading ? 'Submitting...' : 'Continue'} {'>'}</h5>
                            </button>
                        </footer>
                    </form>
                </motion.div>
            </div >
        </>
    );
}
