import React, { useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Set workerSrc to load pdf.worker.js (required by pdfjs)
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function Testing() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Directly use the S3 URL for the file
  const s3FileUrl = "https://s3-hamza-new.s3.ap-south-1.amazonaws.com/Investor%20Pitch%20Deck-1710273516894.pdf"

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        file={s3FileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{
          cMapUrl: 'cmaps/',
          cMapPacked: true,
        }}
      >
        <Carousel
          showDots={false}
          responsive={responsive}
          autoPlay={false}
          autoPlaySpeed={2000}
          transitionDuration={500}
          infinite={true}
          swipeable={false}

          style={{ width: 'auto', margin: 'auto', padding: "1rem" }} // Centering the carousel
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div style={{ display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            </div>
          ))}
        </Carousel>
      </Document>


      {/* <div>
      <Document file={s3FileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div> */}
    </div>
  );
}

export default Testing;
