import React from 'react'
import sessionstorage from "sessionstorage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { authActions } from "../../redux-store";
import Classes from './MobileVersionPopup.module.css'

function MobileVersionPopup() {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const handleLogout = async () => {
        localStorage.clear();
        sessionstorage.clear();
        navigateTo("/");
        await dispatch(authActions.logout());
        // alert("Signed out successfully")
    };

    return (
        <>
            <div className={Classes.hero} >
                <img className={Classes.icon} src='/icons/white-icon.png' alt='bg' />
                <div className={Classes.header} >
                    <img className={Classes.bg} src='/icons/mobile-bg.png' alt='bg' />
                </div>
                <img className={Classes.infoIcon} src='/icons/info.png' alt='bg' />
                <h3>Sign in on desktop or laptop to continue</h3>
                <h5>
                    We recommend using the web version of <br />
                    PitchPerfecter.ai to get the best experience <br />
                    creating and perfecting your pitch deck.
                </h5>
                <p className={Classes.para} >Any questions or concerns:</p>
                <a href='/contact-us' >
                    <button> <h5>Contact us</h5> </button>
                </a>
                <p onClick={() => handleLogout()} className={Classes.goBackText} >Back to homepage</p>
            </div>
        </>
    )
}

export default MobileVersionPopup
