/* eslint-disable import/no-anonymous-default-export */
export default {

  // Auth
  SET_USER_DATA: 'SET_USER_DATA',
  USER_LOGOUT: 'USER_LOGOUT',
  SET_AUTH_LOADING: 'SET_AUTH_LOADING',
  SET_AUTH_ERRORS: 'SET_AUTH_ERRORS',
  SET_LOGIN_ERRORS: 'SET_LOGIN_ERRORS',
  SET_REGISTER_ERRORS: 'SET_REGISTER_ERRORS',
  SET_PROFILE_EXISTS: 'SET_PROFILE_EXISTS',
  SET_PASSWORD_CHANGE_SUCCESS: 'SET_PASSWORD_CHANGE_SUCCESS',

  
}
