// reducers.js
import { SET_SIDEBAR } from './actions';

const initialState = {
  sidebarValue: {},
};

const sideBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      return {
        ...state,
        sidebarValue: action.payload,
      };
    default:
      return state;
  }
};

export default sideBarReducer;
