import React, { useState, useRef } from "react";
import "./Popup.css";
import CloseIcon from "@mui/icons-material/Close";
import backend from "../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux-store";
import { useNavigate } from "react-router-dom";

function AuthPopup(props) {
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [title, setTitle] = useState("");
  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [state, setState] = useState(1);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // console.log(isChecked); // log the current value of the checkbox
  }


  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handleFirstNameChange(event) {
    setFullName(event.target.value);
  }

  function handleSurnameChange(event) {
    setSurname(event.target.value);
  }


  const [selectedOptions, setSelectedOptions] = useState("");
  const handlePayboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedOptions(value);
    }
  };

  console.log(selectedOptions)



  const loginSubmitHandler = async (e) => {
    e.preventDefault();
    const isLogin = await dispatch(authActions.login(email, password));
    // console.log(dispatch(authActions.login(email, password)))
    
    if (isLogin) {
      toast.success("Login Successfully");
      if(selectedOptions ==="€1 / monthly" ) {
         window.open("https://buy.stripe.com/eVa8yPc8X3cW0nu6oq")
      }

      if(selectedOptions ==="€30 / annually" ) {
        window.open("https://buy.stripe.com/28oaGX1uj6p8fio001")
     }

      props.onClose();
      navigateTo("/home")
    }
    if (!isLogin) {
      toast.error("Wrong Credentials");
    }
  };

  const SignUpHandler = async (e) => {
    e.preventDefault();
    try {
      if(email !="" && email!=null && email !=undefined &&
      password !="" && password!=null && password !=undefined
      ) {
      if(email.includes("@")  && password.length>5) {
      const isSignup = await dispatch(
        authActions.signUp(email, password, fullname)
      );
      if (isSignup) {
        toast.success("Signed Up Successfully");
        if(selectedOptions ==="€1 / monthly" ) {
          window.open("https://buy.stripe.com/eVa8yPc8X3cW0nu6oq")
       }
 
       if(selectedOptions ==="€30 / annually" ) {
         window.open("https://buy.stripe.com/28oaGX1uj6p8fio001")
      }
        props.onClose();
        navigateTo("/home")
      }
      if (!isSignup) {
        toast.error("Wrong credentials");
      }
    
  }
    else {
      toast.error('Enter correct email & password length>5')
    } 
    } else {
      toast.error('Enter correct email & password length>5')
    } 
    } catch (e) {
      toast.error("Network Error");
    }
  };
  
  const ForgotHandler = async (e) => {
    e.preventDefault();
    try {
      const isforgot = await dispatch(authActions.forgotPassword(email));
       if (isforgot) {
        toast.success('Password sent on email')
       } else {
        toast.error('Enter valid registered email')
       }
   }  catch (err) {
      toast.error('Network error')
    }
    }

    
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    state==1?
    <div className="popup">
      <div className="popup-inner">
          <div>
            <CloseIcon className="close-btn" onClick={props.onClose} />
            <form>
              <div className="form-group">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label className="label2" style={{color:"white", fontSize:"18px", fontWeight:"600"}}>
                    Please login :)
                  </label>
                </div>
                <div
                  style={{
                    borderBottom: "2px solid #4f4f4f",
                    width: "55%",
                    alignSelf: "center",
                    margin: "1rem auto",
                  }}
                ></div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="third-input"
                  placeholder="Email"
                  required
                  onChange={(e)=>setEmail(e.target.value)}
                  className="input-box1"
                  style={{
                    height: "44px",
                    border: "1.5px solid rgba(23, 42, 55, 1)",
                    marginBottom: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  id="third-input"
                  placeholder="Password"
                  required
                  onChange={(e)=>setPassword(e.target.value)}
                  className="input-box1"
                  style={{
                    height: "44px",
                    border: "1.5px solid rgba(23, 42, 55, 1)",
                    marginBottom: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                />
              </div>

              <div>
  <label
        style={{color:"white", background:"black", padding:"0.5rem", borderRadius:"10px"}}
  >
    <input
      type="radio"
      name="paymentOption"
      value="Free Plan (No Payment Now)"
      onChange={handlePayboxChange}
    />
    Free Plan (No Payment Now)
  </label>
</div>
<div>
  <label
          style={{color:"white", background:"black", padding:"0.5rem", borderRadius:"10px"}}
  >
    <input
      type="radio"
      name="paymentOption"
      value="€1 / monthly"
      onChange={handlePayboxChange}
    />
    €3 / monthly
  </label>
</div>
<div>
  <label
     style={{color:"white", background:"black", padding:"0.5rem", borderRadius:"10px"}}
  >
    <input
      type="radio"
      name="paymentOption"
      value="€11.99 / monthly"
      onChange={handlePayboxChange}
    />
    €30 / annually
  </label>
</div>

    
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    className="weekly-label"
                    htmlFor="weekly-email"
                    style={{
                      marginRight: "10px",
                      fontWeight: "100",
                      fontSize: "13px",
                      margin: "1rem auto",
                      color:"white"
                    }}
                  >
                    Don't have an account? 
                    <a
                      style={{ color: "blue", textDecoration: "none", color:"rgb(255 185 0)" }}
                      onClick={()=>setState(2)}
                    >
                       {" "}Sign up
                    </a> <br/>
                    <a
                      style={{ color: "blue", textDecoration: "none", textAlign: "left", color:"rgb(255 185 0)" }}
                      onClick={()=>setState(3)}
                    >
                       forgot Password
                    </a> 
                  </label>
                
                </div>
                
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <ReCAPTCHA
                    sitekey="6Lft2HQlAAAAAOdGeIa_41EcoUD7epMXaM3EDE_O"
                    onChange={handleChange}
                  /> */}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
              <br/>

                <button
                  style={{
                    background: "white",
                    color: "#E8828D",
                    border: "1px solid #E8828D",
                    width: "148px",
                    height: "36px",
                    fontSize: "14px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 500,
                    paddingTop: "0.25rem",
                    paddingBottom: "0.25rem",
                    marginTop: "0.3rem",
                    // marginRight: "1rem",
                  }}
                  onClick={(e) => loginSubmitHandler(e)}

                >
                  Login
                </button>
              </div>
              <br/>
            </form>
          </div>
      </div>
    </div>:
    state===2?

    <div className="popup">
      <div className="popup-inner">
          <div>
            <CloseIcon className="close-btn" onClick={props.onClose} />
            <form>
              <div className="form-group">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label className="label2" style={{color:"white", fontSize:"18px", fontWeight:"600"}}>
                    Please create an account:)
                  </label>
                </div>
                <div
                  style={{
                    borderBottom: "2px solid #4f4f4f",
                    width: "55%",
                    alignSelf: "center",
                    margin: "1rem auto",
                  }}
                ></div>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  id="third-input"
                  placeholder="First name"
                  className="input-box1"
                  onChange={handleFirstNameChange}
                  style={{
                    height: "44px",
                    border: "1.5px solid rgba(23, 42, 55, 1)",
                    marginBottom: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="third-input"
                  placeholder="Surename"
                  onChange={handleSurnameChange}
                  className="input-box1"
                  style={{
                    height: "44px",
                    border: "1.5px solid rgba(23, 42, 55, 1)",
                    marginBottom: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="third-input"
                  placeholder="Email"
                  onChange={handleEmailChange}
                  className="input-box1"
                  style={{
                    height: "44px",
                    border: "1.5px solid rgba(23, 42, 55, 1)",
                    marginBottom: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                />

                <input
                  type="password"
                  id="third-input"
                  placeholder="Password"
                  onChange={(e)=>setPassword(e.target.value)}
                  className="input-box1"
                  style={{
                    height: "44px",
                    border: "1.5px solid rgba(23, 42, 55, 1)",
                    marginBottom: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                />

                
              <div>
  <label 
          style={{color:"white", background:"black", padding:"0.5rem", borderRadius:"10px"}}

  >
    <input
      type="radio"
      name="paymentOption"
      value="Free Plan (No Payment Now)"
      onChange={handlePayboxChange}
    />
    Free Plan (No Payment Now)
  </label>
</div>
<div>
  <label
          style={{color:"white", background:"black", padding:"0.5rem", borderRadius:"10px"}}

  >
    <input
      type="radio"
      name="paymentOption"
      value="€1 / monthly"
      onChange={handlePayboxChange}
    />
    €3 / monthly
  </label>
</div>
<div>
  <label
          style={{color:"white", background:"black", padding:"0.5rem", borderRadius:"10px"}}
  >
    <input
      type="radio"
      name="paymentOption"
      value="€11.99 / monthly"
      onChange={handlePayboxChange}
    />
    €30 / annually
  </label>
</div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <label
                    className="weekly-label"
                    htmlFor="weekly-email"
                    style={{
                      marginRight: "10px",
                      fontWeight: "100",
                      fontSize: "13px",
                      margin: "1rem auto",
                    }}
                  >
                    By creating an account you agree to our{" "}
                    <a
                      href="https://docs.google.com/document/d/1v2cCnzW3kn1frNQSoFN_AUB7QFW5u4yT-RHroQclUyo/edit?usp=sharing"
                      target="_black"
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      terms of use
                    </a> </label> */}
                  {/* <input
                    type="checkbox"
                    id="weekly-email"
                    onChange={handleCheckboxChange}
                    style={{
                      width: "30px",
                      height: "16px",
                      border: "2px solid #87C7F1",
                      borderRadius: "5px",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  /> */}
                  
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    className="weekly-label"
                    htmlFor="weekly-email"
                    style={{
                      marginRight: "10px",
                      fontWeight: "100",
                      fontSize: "13px",
                      margin: "1rem auto",
                      color:"white"
                    }}
                  >
                    Already have an account? 
                    <a
                      style={{ color: "blue", textDecoration: "none", color:"rgb(255 185 0)" }}
                      onClick={()=>setState(1)}
                    >
                       {" "}Login here.
                    </a> <br/>
                    <a
                      style={{ color: "blue", textDecoration: "none", textAlign: "left", color:"rgb(255 185 0)"}}
                      onClick={()=>setState(3)}
                    >
                       forgot Password
                    </a> 
                  </label>
                 
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <ReCAPTCHA
                    sitekey="6Lft2HQlAAAAAOdGeIa_41EcoUD7epMXaM3EDE_O"
                    onChange={handleChange}
                  /> */}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
              <br/>

                <button
                  style={{
                    background: "white",
                    color: "#E8828D",
                    border: "1px solid #E8828D",
                    width: "148px",
                    height: "36px",
                    fontSize: "14px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 500,
                    paddingTop: "0.25rem",
                    paddingBottom: "0.25rem",
                    marginTop: "0.3rem",
                    // marginRight: "1rem",
                  }}
                  onClick={(e) => SignUpHandler(e)}
                >
                  Sign up
                </button>
                
              </div>
              
              <br/>
            </form>
          </div>
      </div>
    </div>:
    <div className="popup">
      <div className="popup-inner">
<div>
<CloseIcon className="close-btn" onClick={props.onClose} />
<form>
  <div className="form-group">
    <label style={{ fontSize: "14px", fontWeight: "600" }}>
      Add Email
    </label>
    <input
      type="text"
      id="third-input"
      placeholder="Enter email here"
      className="input-box1"
      onChange={(e)=>setEmail(e.target.value)}
      style={{
        height: "44px",
        border: "1.5px solid rgba(23, 42, 55, 1)",
        marginBottom: "0.5rem",
        paddingLeft: "0.5rem",
      }}
    />
  </div>
  <div style={{ display: "flex", justifyContent: "flex-end" }}>
  <br/>

    <button
      style={{
        background: "white",
        color: "#E8828D",
        border: "1px solid #E8828D",
        width: "148px",
        height: "36px",
        fontSize: "14px",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
        marginTop: "0.3rem",
        marginRight: "1rem",
      }}
      onClick={(e) => ForgotHandler(e)}
    >
      Send
    </button>
  </div>
</form>
<br/>
</div>
</div>
</div>
  );
}

export default AuthPopup;
