// reducers.js
import { SET_STEPPER_VALUE } from './actions';

const initialState = {
    stepperValue: '',
};

const stepperReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STEPPER_VALUE:
      return {
        ...state,
        stepperValue: action.payload,
      };
    default:
      return state;
  }
};

export default stepperReducer;
