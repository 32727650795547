import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Classes from './SectionD.module.css';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
const DeckAnalyzerIcon = (props) => (
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_1151511"
    {...props} // Allows additional props to be passed
  >
    <path d="m351.332031 153.660156v-88.453125c0-35.953125-29.253906-65.207031-65.210937-65.207031h-220.917969c-35.953125 0-65.203125 29.253906-65.203125 65.207031v147.714844c0 30.796875 21.457031 56.671875 50.203125 63.46875v36.949219c0 6.066406 3.65625 11.535156 9.261719 13.859375 1.855468.769531 3.804687 1.140625 5.738281 1.140625 3.902344 0 7.738281-1.523438 10.609375-4.394532l45.8125-45.8125h9.046875v-29.265624c0-52.5 42.707031-95.207032 95.203125-95.207032zm0 0"></path>
    <path d="m446.792969 183.660156h-220.917969c-35.953125 0-65.203125 29.25-65.203125 65.207032v147.714843c0 35.957031 29.25 65.207031 65.203125 65.207031h164.496094l45.8125 45.816407c2.871094 2.871093 6.707031 4.394531 10.613281 4.394531 1.929687 0 3.878906-.371094 5.734375-1.140625 5.605469-2.324219 9.261719-7.792969 9.261719-13.859375v-36.949219c28.75-6.792969 50.207031-32.671875 50.207031-63.46875v-147.714843c0-35.957032-29.253906-65.207032-65.207031-65.207032zm-46.597657 114.167969-71.007812 71.007813c-2.929688 2.929687-6.769531 4.390624-10.605469 4.390624-3.839843 0-7.679687-1.460937-10.605469-4.390624l-35.507812-35.503907c-5.859375-5.855469-5.859375-15.355469 0-21.214843 5.855469-5.855469 15.355469-5.855469 21.210938 0l24.902343 24.898437 60.398438-60.402344c5.859375-5.855469 15.355469-5.855469 21.214843 0 5.859376 5.859375 5.859376 15.359375 0 21.214844zm0 0"></path>
  </svg>
);

const SectionD = () => {

  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate('/signup', { state: { data: "toSignup" } });
  }

  const [width, setWidth] = useState(window.innerWidth);
  const sectionRef = useRef(null); // Reference to the section

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id="how-it-works" ref={sectionRef} className={Classes.section}>
      <div className={Classes.card} >
        <h1 className={Classes.subheading}> Screen companies in minutes, not days. </h1>
        <p className={Classes.text}>
          Comprehensive company analysis with your custom venture-capitalist AI.
        </p>
        <div className={Classes.boxesList} >
          <div className={Classes.box} >
            <div className={Classes.miniBox} >
              {/* <img src='/icons/brush-white.png' alt='brush icon' /> */}
              <svg
                id="fi_10337003"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                height='23px'
                fill='white'
              >
                <g id="Layer_2_00000124881960921214502080000013090863086384764563_">
                  <g id="paintbrush">
                    <path d="m512 13.8c-1.1 3.1-2.1 8.6-4.8 13.2-10.8 18.4-21.2 37.2-33.4 54.8-38.7 55.9-81.8 108.3-126.1 159.7-29.1 33.8-58.3 67.7-91.9 97.2-14.3 12.6-29.9 23.8-45 35.5-4.4 3.4-6.5 2.9-9-2-14.1-27.6-35.3-48.1-63-62-5.1-2.5-5.3-4.2-1.7-9.2 22.2-31.1 48.3-58.8 76.8-84.2 71.7-63.8 146-124.2 225.6-178 16.2-10.8 33.5-19.8 50.4-29.2 4.5-2.5 9.9-3.6 15-4.5 4.7-.9 7.1 1.7 7.1 8.7z"></path>
                    <path d="m22.1 507.2c-3.1-.4-9-.8-14.7-2.2-8.4-2.1-9.4-6.5-4.2-13.3 5.6-7.3 11.4-14.5 16.4-22.2 11.6-18 16-38.4 18.9-59.2 1.2-9.1 2.4-18.4 5.2-27.1 7.1-22.8 20.8-39.9 44.8-46.5 9.1-2.5 18.8-2.3 27.8.6 29.4 9.1 50 28.2 60.5 57.1 8 22.1 2.6 41.8-14.6 58-19.9 18.7-43.4 31.3-68.9 40.5-22.8 8.6-46.9 13.4-71.2 14.3z"></path>
                  </g>
                </g>
              </svg>
            </div>
            <h5 className={Classes.boxTitle} > 01 </h5>
            <h5 className={Classes.boxTitle} > Create your screening tool </h5>
            <h5 className={Classes.boxText} > Customize your pitch deck screening tool <br /> in minutes without any onboarding calls, <br /> and select the AI model that best fits <br /> your needs.</h5>
            <div className={Classes.boxRightLine} ></div>
          </div>
          <div className={Classes.box} >
            <div className={Classes.miniBox} >
              <img src='/icons/worldIconWhite.png' alt='brush icon' />
            </div>
            <h5 className={Classes.boxTitle} > 02 </h5>
            <h5 className={Classes.boxTitle} > Send out landing page </h5>
            <h5 className={Classes.boxText} > Provide applicants with your unique <br /> landing page link where they can sign up <br /> and review key information about <br /> your ecosystem. </h5>
            <div className={Classes.boxLeftLine} ></div>
            <div className={Classes.boxRightLine} ></div>
          </div>
          <div className={Classes.box} >
            <div className={Classes.miniBox} >
              {/* <img src='/icons/deck-analyzer-white.png' alt='brush icon' /> */}
              <DeckAnalyzerIcon style={{ height: '23px', fill: 'white' }} />
            </div>
            <h5 className={Classes.boxTitle} > 03 </h5>
            <h5 className={Classes.boxTitle} > Companies submit application </h5>
            <h5 className={Classes.boxText} >
              Applicants provide basic company details, <br /> such as industry and description, <br /> then instantly submit their pitch <br /> decks for screening.
            </h5>
            <div className={Classes.boxRightLine} ></div>
            <div className={Classes.boxLeftLine} ></div>
          </div>
          <div className={Classes.box} >
            <div className={Classes.miniBox} >
              {/* <img src='/icons/screening-white.png' alt='brush icon' /> */}
              <svg height='25px' fill='white' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" id="fi_4947880"><g id="Layer_4" data-name="Layer 4"><rect height="42" rx="2" width="78.97" x="10.51" y="39.44"></rect><path d="m20 33.26 60 .35a2 2 0 0 0 0-4l-60-.35a2 2 0 0 0 0 4z"></path><path d="m29.48 22.56 41 .35a2 2 0 0 0 0-4l-41-.35a2 2 0 0 0 0 4z"></path></g></svg>
            </div>
            <h5 className={Classes.boxTitle} > 04 </h5>
            <h5 className={Classes.boxTitle} > Review key metrics </h5>
            <h5 className={Classes.boxText} > Review key metrics of submitted pitch <br /> decks, including company description, <br /> industry, a comprehensive summary, and <br /> an overall pitch deck score. </h5>
            <div className={Classes.boxLeftLine} ></div>
          </div>
        </div>
        <motion.div
          whileTap={{ scale: 0.97 }}
          whileHover={{ scale: 1.03 }}
        >
          <button className={Classes.startBtn} onClick={navigateToSignup} > <h5>Get started for free</h5> </button>
        </motion.div>
        <div className={Classes.text} style={{ margin: '0', fontSize: '14px' }} > No credit card required </div>
      </div>
    </section>
  );
};

export default SectionD;
