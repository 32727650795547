import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
// import "./ChatHistory.css";
import backend from "../../../../api";
import { useNavigate } from "react-router-dom";

import { setChatHistoryId } from "../../../../redux-store/auth/actions";
import ChatIcon from '@mui/icons-material/Chat';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import Button from "@mui/material/Button";

import Classes from './ChatHistory.module.css';

const styles = {
  container: {
    width: 'auto',
    cursor: 'pointer',
    border: '1px solid rgb(9 70 122)',
    borderRadius: '20px',
    margin: '1rem',
    paddingLeft: '1rem',
    backgroundColor: 'white',
    display: 'flex',
    maxHeight: "70px",
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  quote: {
    fontStyle: 'italic',
  },
  copyIcon: {
    marginTop: 'auto',
  },
};

function ChatHistory() {
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const _id = user._id;
  const projectId = localStorage.getItem("projectID")
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getChats = async () => {

      const UserId = user._id;
      setLoading(true)
      try {
        const response = await backend.get("/chats");
        // Filter the response data to include only the required objects
        const filteredChats = response?.data?.data?.filter(
          (item) => item.UserId === UserId && item.chat.length > 0
        );
        // setLoading(false);
        // console.log(filteredChats);
        setData(filteredChats);
        console.log(response);
        setLoading(false)
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };
    getChats();
  }, [projectId]);


  const OpenChatHistory = (id) => {
    dispatch(setChatHistoryId(id))
    navigateTo(`/chatbot/${id}`);
  };

  const ProjectID = localStorage.getItem('projectID');

  const saveChat = async () => {
    const userId = user._id
    setLoading(true)
    try {
      const body = {
        UserId: userId,
        projectId: "test"
      };

      const response = await backend.post("/chats", body);
      // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      if (RouteID) {
        await dispatch(setChatHistoryId(RouteID))
        // await UpdateProjectById(RouteID)
        navigateTo(`/chatbot/${RouteID}`)
      }

      setLoading(false)

    } catch (error) {
      console.error(error);
      setLoading(false)

      // toast.error("Error saving story");
    }

  };

  const DeleteChat = async (id) => {

    const UserId = user._id;
    setLoading(true)
    try {
      const response = await backend.delete(`/chats/${id}`);
      // Filter the response data to include only the required objects
      const filteredChats = data?.filter(
        (item) => item.UserId === UserId && item.chat.length > 0 && item._id !== id
      );
      // setLoading(false);
      // console.log(filteredChats);
      setData(filteredChats);
      console.log(response);
      setLoading(false)
    } catch (error) {
      console.error(error);
      // toast.error("Error saving story");
    }
  };

  console.log(data)
  return (
    <div className={Classes.container} >
      <button className={Classes.newChatBtn} onClick={() => saveChat()} >
        <span>+</span>New Chat
      </button>
      {data?.some(value => {
        const createdAtDate = new Date(value.createdAt);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - createdAtDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays <= 7;
      }) && (
          <div className={Classes.con1}>
            <>
              <h5>Previous 7 days</h5>
              {data.map(value => {
                const createdAtDate = new Date(value.createdAt);
                const currentDate = new Date();
                const diffTime = Math.abs(currentDate - createdAtDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays <= 7) {
                  return (
                    <div className={Classes.listCon} key={value._id}>
                      <h5 onClick={() => OpenChatHistory(value._id)}>
                        {value.chat[0]?.content?.length > 20 ? `${value.chat[0]?.content?.slice(0, 20)}...` : value.chat[0]?.content}
                      </h5>
                      <DeleteIcon className={Classes.deleteIcon} onClick={() => DeleteChat(value._id)} />
                    </div>
                  );
                } else {
                  return null; // If value is not within the desired time range, return null
                }
              })}
            </>
          </div>
        )}

      {data?.some(value => {
        const createdAtDate = new Date(value.createdAt);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - createdAtDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays > 7 && diffDays <= 30;
      }) && (
          <div className={Classes.con1}>
            <>
              <h5>Previous 30 days</h5>
              {data.map(value => {
                const createdAtDate = new Date(value.createdAt);
                const currentDate = new Date();
                const diffTime = Math.abs(currentDate - createdAtDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays > 7 && diffDays <= 30) {
                  return (
                    <div className={Classes.listCon} key={value._id}>
                      <h5 onClick={() => OpenChatHistory(value._id)}>
                        {value.chat[0]?.content?.length > 20 ? `${value.chat[0]?.content?.slice(0, 20)}...` : value.chat[0]?.content}
                      </h5>
                      <DeleteIcon className={Classes.deleteIcon} onClick={() => DeleteChat(value._id)} />
                    </div>
                  );
                } else {
                  return null; // If value is not within the desired time range, return null
                }
              })}
            </>
          </div>
        )}

    </div>
  );
}

export default ChatHistory;
