import { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

export function AnimateTopToBottom({ children, delay }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const controls = useAnimation();

    const textVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
    };

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, isInView]);

    return (
        <section ref={ref}>
            <motion.p
                initial="hidden"
                animate={controls}
                variants={textVariants}
                transition={{ duration: 0.75, delay }}
            >
                {children}

            </motion.p>
        </section>
    );
}
