import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { AnimateTopToBottom } from '../Home-New/Components/Animations/AnimateTopToBottom';
import { useDispatch, useSelector } from "react-redux";
import backend from '../../api';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function ContactUs() {
    const { user } = useSelector((state) => state.auth);
    const [sendMsg, setsendMsg] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const [emptyCompanyTypeError, setemptyCompanyTypeError] = useState(false);

    const [formdata, setformdata] = useState({
        fullName: '',
        companyWebsite: '',
        email: '',
        companyType: '',
        message: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setformdata({
            ...formdata,
            [name]: value,
        })
    }

    const handleChange2 = (event) => {
        const { value } = event.target;
        setformdata((prevformdata) => ({
            ...prevformdata,
            companyType: value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setemptyCompanyTypeError(false);
        if (formdata.companyType === '') {
            setemptyCompanyTypeError(true);
            setTimeout(() => {
                setemptyCompanyTypeError(false);
            }, 30000);
            return;
        }
        console.log(formdata);
        const body = {
            fullName: formdata.fullName,
            companyWebsite: formdata.companyWebsite,
            email: formdata.email,
            companyType: formdata.companyType,
            message: formdata.message,
        };

        if (formdata.fullName != "" && formdata.companyWebsite != "" && formdata.message != "" && formdata.email.includes("@") && formdata.companyType != "") {
            try {
                const response = await backend.post("/contact", body);
                console.log(response)
                setformdata({
                    fullName: '',
                    companyWebsite: '',
                    email: '',
                    companyType: '',
                    message: '',
                })
                if (response) {
                    await backend.post("/email/acknowledge", body);
                    setsendMsg(true);
                    setTimeout(() => {
                        setsendMsg(false);
                    }, 30000);
                }
            } catch (error) {
                console.log(error)
                alert('Network error')
            }
        } else {
            alert('All fields are required')
        }
    };

    return (
        <>
            <div className={Classes.backgroundImage} >
                <AnimateTopToBottom delay={0.3}>
                    {user &&
                        <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
                            <div>
                                <a className={Classes.logoLink} href="/">
                                    {width <= 480 ?
                                        <img className={Classes.minilogoImage} src='/icons/miniLogo.png' alt="Logo" />
                                        :
                                        <img className={Classes.logoImage} src='/Blue.png' alt="Logo" />
                                    }
                                </a>
                            </div>
                            <nav className={Classes.navMenu}>

                            </nav>
                            <div>
                                <a href="/" className={Classes.flexAlign} >
                                    <h5 className={Classes.goBackTxt} >Dashboard</h5>
                                    <img src='/icons/arrow-right.png' />
                                </a>
                            </div>
                        </div>
                    }
                </AnimateTopToBottom>
                <AnimateTopToBottom delay={0.3}>
                    <div className={Classes.hero} >
                        <div className={Classes.heroLeft} >
                            <h1 className={Classes.subheading} > Only select the <br /> <span className={Classes.blue} > best </span> companies! </h1>
                            <p className={Classes.text} >
                                With our pitch deck screening tool, you can effortlessly filter through pitch decks using your specific scoring metrics. Say goodbye to overlooking promising ventures—never miss out on a great company again.
                            </p>
                            <div className={Classes.list} >
                                <div className={Classes.listItem} >
                                    <img src='/icons/tickFilled.png' alt='tick' />
                                    <p className={Classes.text} >Custom branding</p>
                                </div>
                                <div className={Classes.listItem} >
                                    <img src='/icons/tickFilled.png' alt='tick' />
                                    <p className={Classes.text} >Custom upload limits</p>
                                </div>
                                <div className={Classes.listItem} >
                                    <img src='/icons/tickFilled.png' alt='tick' />
                                    <p className={Classes.text} >Custom AI training</p>
                                </div>
                                <div className={Classes.listItem} >
                                    <img src='/icons/tickFilled.png' alt='tick' />
                                    <p className={Classes.text} >Custom API integrations</p>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className={Classes.form_con} >
                            <div className={Classes.form_con_row} >
                                <input
                                    className={Classes.input_field}
                                    placeholder='Full name'
                                    name='fullName'
                                    type='text'
                                    required
                                    value={formdata.fullName}
                                    onChange={handleChange}
                                />
                                <input
                                    className={Classes.input_field}
                                    placeholder='Company website'
                                    name='companyWebsite'
                                    type='text'
                                    required
                                    value={formdata.companyWebsite}
                                    onChange={handleChange}
                                />
                            </div>
                            <input
                                className={Classes.input_field}
                                placeholder='Email'
                                name='email'
                                type='email'
                                required
                                value={formdata.email}
                                onChange={handleChange}
                            />
                            <div className={Classes.companyType} >
                                <label htmlFor='' >
                                    <h5>
                                        Company type &nbsp;
                                        {emptyCompanyTypeError && <span className='red'> *required </span>}
                                    </h5>
                                </label>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    className={Classes.RadioGroup}
                                >
                                    <FormControlLabel className={Classes.radioCon} name='companyType' onChange={handleChange2} value="Accelerator" control={<Radio />} label="Accelerator" />
                                    <FormControlLabel className={Classes.radioCon} name='companyType' onChange={handleChange2} value="Competition" control={<Radio />} label="Competition" />
                                    <FormControlLabel className={Classes.radioCon} name='companyType' onChange={handleChange2} value="Investment firm" control={<Radio />} label="Investment firm" />
                                    <FormControlLabel className={Classes.radioCon} name='companyType' onChange={handleChange2} value="other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </div>
                            <textarea
                                rows={5}
                                className={Classes.input_field}
                                placeholder='Message'
                                name='message'
                                type='text'
                                value={formdata.message}
                                onChange={handleChange}
                                required
                            />

                            <div className={Classes.btnCon} >
                                <button type='submit' > <h5 className={Classes.m0} > Schedule demo call </h5> </button>
                                {sendMsg &&
                                    <div className={Classes.msgCon} >
                                        <img className={Classes.checkmark} src='./icons/check-mark.png' />
                                        <h5> Your inquiry was successfully received. We will get back to you within 48 hours. </h5>
                                    </div>
                                }
                            </div>

                        </form>
                    </div>
                </AnimateTopToBottom>
            </div>
        </>
    )
}

export default ContactUs
