import React from 'react';

const Blog = () => {
  return (
    <div style={{ width: '100%', height: '100vh', border: 'none' }}>
      <iframe
        src="https://brafei1.dreamhosters.com/"
        title="Blog Page"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default Blog;
