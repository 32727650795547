import React, { useState, useEffect, useRef } from 'react';
import { motion, easeOut } from "framer-motion";
import Classes from './SectionB.module.css';
import { Animate1 } from './Animations/Animate1';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const videoLink = 'https://pitch-bucket-s3.s3.us-east-2.amazonaws.com/PitchPerfecter+Blue+Pitch+deck.mp4'; //new video

function SectionB() {
  const videoRef = useRef(null);
  const [autoplay, setAutoplay] = useState(false);

  return (
    <section className={Classes.howItWorks}>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.5,
          ease: easeOut,
          delay: 2.8
        }}
      >
        <LazyLoadImage src='/demo-image-blue.png' className={Classes.demoImage} alt='demo image' draggable={false} />
      </motion.div>
      <div className={Classes.trustedCompanies} >
        <h5> Trusted by accelerators, competitions, and investment firms worldwide </h5>
        <div className={Classes.companiesList} >
          <LazyLoadImage
            effect="opacity" src='/brand1.png' alt='brand1' />
          {/* <LazyLoadImage
            effect="opacity" src='/brand2.png' alt='brand2' /> */}
          <LazyLoadImage
            effect="opacity" className={Classes.customWidth9} src='/brand3.png' alt='brand3' />
          <LazyLoadImage
            effect="opacity" src='/brand4.png' alt='brand4' />
          <LazyLoadImage
            effect="opacity" src='/brand5.png' alt='brand5' />
          <LazyLoadImage
            effect="opacity" src='/brand6.png' alt='brand6' />
        </div>
      </div>
    </section>
  );
}

export default SectionB;
