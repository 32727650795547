// reducers.js
import { SET_COMPANY_INFO } from './actions';

const initialState = {
  info: {},
};

const companyInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_INFO:
      return {
        ...state,
        info: action.payload,
      };
    default:
      return state;
  }
};

export default companyInfoReducer;
