import axios from 'axios'

const backend = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://evalaai-9ed507d4a9ee.herokuapp.com/'
      : 'https://evalaai-9ed507d4a9ee.herokuapp.com/'
      // : 'http://localhost:8080/'
})


backend.interceptors.request.use(
  (config) => {
    let user = ''
    if (!user)
      user = localStorage?.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    if (user) config.headers = { Authorization: `Bearer ${user.token}` }
    return config
  },
  (error) => Promise.reject(error)
)

export default backend
