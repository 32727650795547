import React, { useState, useEffect } from 'react';
import { LinearProgress, Box, Typography, styled, Grid } from '@mui/material';

import Color from 'color';

const convertToRgba = (colorInput, opacity) => {
  try {
    const color = Color(colorInput);
    return color.alpha(opacity).rgb().string();
  } catch (error) {
    console.error("Invalid color format:", colorInput);
    // Fallback to a default color in case of an error
    return `rgba(0, 0, 0, ${opacity})`;  // Default to transparent black
  }
};

const onBoarding = {
  companyName: "e-Fest",
  companyWebsite: "efest.pitchScreen.ai",
  color: "#ED3A23",
  logo: "/onBoarding/efest.png",
};

// Custom style for the LinearProgress component
// Custom style for the LinearProgress component using the styled utility
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 25,

  width: "100%",
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: onBoarding.color,
    borderRadius: 25
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: convertToRgba(onBoarding.color, 0.2),
  },
}));

const LoadingLineWithPercentage = () => {
  // State to hold the current progress value
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 7500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{
      width: '80%', mt: 2, display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginTop: " 5.5rem"
    }}>
      <img src={onBoarding.logo} alt='bulb' style={{ height: "65px", marginBottom: '3rem' }} />
      <Typography variant="body1" gutterBottom style={{ fontSize: "20px", textAlign: "center", marginBottom: '1rem', fontWeight: '700' }}>
        Your pitch deck is being analyzed by our AI
      </Typography>
      <p style={{ fontSize: "16px", color: "#424242", textAlign: "center" }}>
        This may take a few minutes, please don’t navigate away from this page.<br />
        In the meantime here are some pitch deck tips.
      </p>
      {/* ... other text components */}
      <Box position="relative" display="inline-flex" width="100%" alignItems="center" style={{ width: "50%", marginTop: "4rem" }}>
        <BorderLinearProgress variant="determinate" style={{ backgroundColor: convertToRgba(onBoarding.color, 0.2) }} value={progress} />
        <Box
          sx={{
            position: 'absolute',
            left: `${progress}%`,
            transform: 'translate(-50%, -50%)',
            ml: '30px',
            mt: '25px'
          }}
        >
          <Typography variant="body3" component="div" color="textPrimary" sx={{ fontWeight: 'bold', fontWeight: "500", fontSize: "20px" }}>
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} style={{ marginTop: "5rem" }}>
        <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          {/* <img src='/icons/bulb.png' alt='bulb' style={{ height: "41px" }} /> */}
          <svg fill={onBoarding.color} xmlns="http://www.w3.org/2000/svg" style={{ height: "41px" }} viewBox="0 0 384 512"><path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z" /></svg>
          <h1 style={{ fontSize: "20px", textAlign: "center" }}>
            Tell a compelling story
          </h1>

          <p style={{ fontSize: "16px", maxWidth: "280px", textAlign: "center", marginTop: "-0.5rem" }}>
            Craft a compelling narrative that highlights
            the problem, solution, and market oppurtunity.
          </p>
        </Grid>

        <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

          {/* <img src='/icons/brush.png' alt='bulb' style={{ height: "41px", width: "41px" }} /> */}
          <svg fill={onBoarding.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "41px" }} ><path d="M339.3 367.1c27.3-3.9 51.9-19.4 67.2-42.9L568.2 74.1c12.6-19.5 9.4-45.3-7.6-61.2S517.7-4.4 499.1 9.6L262.4 187.2c-24 18-38.2 46.1-38.4 76.1L339.3 367.1zm-19.6 25.4l-116-104.4C143.9 290.3 96 339.6 96 400c0 3.9 .2 7.8 .6 11.6C98.4 429.1 86.4 448 68.8 448H64c-17.7 0-32 14.3-32 32s14.3 32 32 32H208c61.9 0 112-50.1 112-112c0-2.5-.1-5-.2-7.5z" /></svg>
          <h1 style={{ fontSize: "20px", textAlign: "center" }}>
            Keep it simple and appealing
          </h1>

          <p style={{ fontSize: "16px", maxWidth: "280px", textAlign: "center", marginTop: "-0.5rem" }}>
            Use a clean, visually appealing design with minimal text to make it engaging, and keep investors interested.
          </p>

        </Grid>

        <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

          {/* <img src='/icons/metric.png' alt='bulb' style={{ height: "41px", width: "42px" }} /> */}
          <svg fill={onBoarding.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "41px" }} ><path d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z" /></svg>
          <h1 style={{ fontSize: "20px", textAlign: "center" }}>
            Focus on key metrics
          </h1>

          <p style={{ fontSize: "17px", maxWidth: "280px", textAlign: "center", marginTop: "-0.5rem" }}>
            Showcase key metrics and traction to
            demonstrate business model effectiveness.
          </p>

        </Grid>

      </Grid>

    </Box>
  );
};

export default LoadingLineWithPercentage;
