import React, { useState, useEffect } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LoadingLineWithPercentage from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { setResponseText2 } from "../../../redux-store/auth/actions";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Modal,
  LinearProgress,
  Container,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import "./Testing1.css";
import { Configuration, OpenAIApi } from "openai";
import Divider from "@mui/material/Divider";
import sessionstorage from "sessionstorage";
import { useNavigate } from "react-router-dom";
import Classes from './PitchAnalyzer.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import PageSelctorComp from "./PageSelctorComp";
import MobileVersionPopup from "../../Global-Components/MobileVersionPopup/MobileVersionPopup";
import SummaryPopup from "./SummaryPopup";
import backend from "../../../api";

import Color from 'color';

const convertToRgba = (colorInput, opacity) => {
  try {
    const color = Color(colorInput);
    return color.alpha(opacity).rgb().string();
  } catch (error) {
    return `rgba(0, 0, 0, ${opacity})`;
  }
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = {
  fontFamily: "Manrope, sans-serif",
  fontSize: "16px",


  color: "black",
  whiteSpace: "pre-wrap", // allow wrapping of long lines
  wordWrap: "break-word", // break long words

};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto", // Adjust width as needed
  maxWidth: "70vw", // Maximum width of the modal is 80% of the viewport width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Enable scrolling if content is too long
  maxHeight: "65vh", // Maximum height is 80% of the viewport height
  // margin: "5vh", // Adds margin to the top & bottom of the modal (5% of the viewport height)
  borderRadius: "15px",
  outline: 'none',
};
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1400 },
    items: 1,
    partialVisibilityGutter: 40, // This will make the carousel responsive to super large screens
  },

  desktop: {
    breakpoint: { max: 1399, min: 1024 },
    items: 1,
    partialVisibilityGutter: 30, // Adjustments for desktop screens
  },
  tablet: {
    breakpoint: { max: 1023, min: 600 },
    items: 1,
    partialVisibilityGutter: 20, // Adjustments for tablet screens
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
    partialVisibilityGutter: 10, // Adjustments for mobile screens
  },
};

const ScoreProgress = ({ value, IconComponent, label }) => {
  const numericValue = parseFloat(value); // Convert string value to number

  // Determine color based on value
  let color;
  if (numericValue >= 100) {
    color = "green"; // Strong
  } else if (numericValue >= 80) {
    color = "lightgreen"; // Low strong
  } else {
    color = "#F44336"; // Weak or normal
  }

  // Determine the color of the LinearProgress based on the score
  const progressColor = (value) => {
    if (value >= 100) return "green"; // Strong
    else if (value >= 80) return "lightgreen"; // Low strong
    else return "#F44336"; // Weak or normal
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img
          src={`/icons/${IconComponent}`}
          style={{ width: "23px", height: "23px", paddingRight: ".5rem" }}
        />
        <p style={{ color: "black", fontWeight: "600" }}>{label}</p>
      </div>

      <Box display="flex" alignItems="center" style={{ marginTop: "-0.8rem" }}>
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={numericValue}
            sx={{
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: progressColor(numericValue),
              },
              backgroundColor: "#eee", // Background color of the progress bar
            }}
          />
        </Box>
        <Box minWidth={35}>
          <Typography
            variant="body2"
            style={{ fontWeight: "600", color: "black" }}
          >{`${Math.round(numericValue)}/100`}</Typography>
        </Box>
      </Box>
    </div>
  );
};

function Testing1() {
  const navBtn = sessionstorage.getItem("navBtn")
  const [selectedButton, setSelectedButton] = React.useState(navBtn ? navBtn : "Pitch Deck Dashboard");
  // const [numPages, setNumPages] = useState(null); -
  const [pageTexts, setPageTexts] = useState([]);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [firstResults, setFirstResults] = useState([]);
  const [CarouselIndex, setCarouselIndex] = useState(0); // To keep track of the current carousel index
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [loadingPage, setLoadingPage] = useState(true); // New state to manage loading
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [isImagesLoaded, setisImagesLoaded] = useState(false);
  // const [s3FileUrl, setS3URL] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [onBoarding, setonBoarding] = useState({
    companyName: "e-Fest",
    companyWebsite: "efest.pitchScreen.ai",
    color: "#ED3A23",
    logo: "/onBoarding/efest.png",
  });

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const gptResults = useSelector((state) => state.responseText2.responseText2);
  const [responsiveWidth, setResponsiveWidth] = useState(600); // Initial width
  const navigateTo = useNavigate();
  const [screenReady, setscreenReady] = useState(false);
  const [openSummary, setopenSummary] = useState(false);
  const [deckData, setDeckData] = useState(null);
  const deckId = useSelector((state) => state.deckId.deckId);
  const deckStatus = localStorage.getItem('deckStatus')


  useEffect(() => {
    const fetchDeckData = async () => {
      // setLoading(true)
      if (deckId) {
        try {
          const response = await backend.get(`/deck/${deckId}`);
          console.log(response?.data)

          if (response?.data) {
            setDeckData(response?.data);
            if (response?.data?.saved) {
              // setLoading(false);
            }
          }
          // if(response.data) {

          //     sendBotMessages();


          // }

          // setLoading(false)
        } catch (error) {
          console.error('Failed to fetch deck data:', error);
          setDeckData(null);
        }
      }
    };

    if (deckStatus !== 'new') {
      fetchDeckData();
    }
  }, [deckId]); // useEffect will re-run when deckId changes

  // console.log(deckData)
  // Assuming you have a function to send the data to the backend
  const updateDeckData = async (data) => {
    try {
      const response = await backend.put(`/deck/${deckId}`, data);
      // console.log(response);
      if (response?.data) {
        // setDeckData(response?.data?.data);
        console.log('Data Saved successfully:', response?.data?.data);

      }
    } catch (error) {
      console.error('Failed to update deck data:', error);
    }
  };

  const handleCloseSummary = () => {
    setopenSummary(false);
  }
  const updateResponsiveWidth = () => {
    // Obtain the window width
    const windowWidth = window.innerWidth;

    // Logic to update the responsive width based on your breakpoints
    if (windowWidth >= 1450) {
      setResponsiveWidth(705); // Desktop width
    } else if (windowWidth >= 1024) {
      setResponsiveWidth(520); // Desktop width
    } else if (windowWidth >= 600) {
      setResponsiveWidth(500); // Tablet width
    } else {
      setResponsiveWidth(windowWidth - 40); // Mobile width, 20px padding on each side
    }
  };

  const handleGoToTemplate = () => {
    sessionstorage.setItem("navBtn", 'Pitch Deck Templates')
    window.location.href = "/templates/";
  }

  useEffect(() => {
    updateResponsiveWidth(); // Update on mount
    window.addEventListener("resize", updateResponsiveWidth); // Update on resize

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateResponsiveWidth);
  }, []);

  const s3FileUrl = localStorage.getItem("pitch");

  useEffect(() => {
    if (!s3FileUrl) {
      sessionstorage.setItem("navBtn", "Pitch Deck Dashboard");
      navigateTo("/dashboard");
    }
  }, [s3FileUrl]);

  // const s3FileUrl = "https://s3-hamza-new.s3.ap-south-1.amazonaws.com/Investor%20Pitch%20Deck-1710273516894.pdf"
  const configuration = new Configuration({
    apiKey: "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",
  });
  const openai = new OpenAIApi(configuration);

  useEffect(() => {

    const fetchPdfText = async () => {

      try {
        const loadingTask = pdfjs.getDocument(s3FileUrl);
        const pdf = await loadingTask.promise;
        const numPages = pdf.numPages;
        const pageTextPromises = [];

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const textContent = await page.getTextContent();
          const texts = textContent.items.map((item) => item.str).join(" ");
          pageTextPromises.push(texts);
        }

        // Resolve all promises to get texts of all pages
        Promise.all(pageTextPromises).then((texts) => {
          setPageTexts(texts);
        });
      } catch (error) {
        console.error("Error loading PDF: ", error);
      }
    };
    fetchPdfText();
  }, []);


  useEffect(() => {
    if (s3FileUrl?.length > 0 && pageTexts?.length > 0 && deckStatus === 'new') {
      sendBotMessages();
    } else {
      if (deckStatus !== 'new') {
        setLoading(false)
      }
    }
  }, [s3FileUrl, pageTexts, deckStatus]);

  const goToDashboard = () => {
    sessionstorage.setItem("navBtn", "Pitch Deck Dashboard");
    navigateTo("/dashboard");
  }

  // useEffect(() => {
  //   if (!deckData && !deckData?.pitchDeckScoreMatch ) {
  //     sendBotMessages();
  //   }
  // }, [deckData]);

  const sendBotMessages = async () => {
    // setLoading(true);
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const fetchResult = async (text, index) => {
      let message;
      if (index == 0) {
        message = `Plz generate results for this complete pitch deck:"${index == 0 ? pageTexts.join(", ") : text
          }" and for the first slide if it is empty then it means its a logo and include details for first page slide design improvements like this: "i. Feedback: Your slide effectively showcases your logo and slogan effectively and is well designed. 
          ii. Suggestions: Possibly include an illustration of your product or service to capture the audience's attention if not done already." That is just an example. And here is first Page slide ${pageTexts[0]}
          and generate in a way that my code should work automatically on your answer for complete deck analysis and don't add brackets/braces or any closings quotation marks. Make it simple text and numbers where required so my code should parse your answers without interruption and with a good comprehensive answer and b. 2. Make scoring more difficult (scores should be lower), Weight the total scores like this:
          i. Deck Structure score: 20 percent.
          ii. Market Potential score: 40 percent.
          iii. Business Model score: 40 percent. 
          overall pitchDeckScoreMatch = (Deck Structure Score * 0.2) + (Market Potential Score * 0.4) + (Business Model Score * 0.4) and don't round off it.
          For the all scores plz give only two numbers out of 100 so code should work in parsing.
          Here is my code where you answer will be parsed:"  
            const feedbackMatch = fullResponse.match(/Feedback: ([\s\S]*?)Suggestions:/);
            const suggestionsMatch = fullResponse.match(/Suggestions: ([\s\S]*?)Pitch Deck score:/);
            const pitchDeckScoreMatch = fullResponse.match(/Pitch Deck score: ([\s\S]*?)Deck structure score out of 100:/);
            const deckStructureScoreMatch = fullResponse.match(/Deck structure score out of 100: ([\s\S]*?)Market Potential score out of 100:/);
            const marketPotentialScoreMatch = fullResponse.match(/Market Potential score out of 100: ([\s\S]*?)Business Model score out of 100:/);
            const businessModelScoreMatch = fullResponse.match(/Business Model score out of 100: (\d+)/);
            const deckStructureParagraphMatch = fullResponse.match(/Deck structure Paragraph: ([\s\S]*?)Market Potential Paragraph:/);
            const marketPotentialParagraphMatch = fullResponse.match(/Market Potential Paragraph: ([\s\S]*?)Business Model Paragraph:/);
            const businessModelParagraphMatch = fullResponse.match(/Business Model Paragraph: ([\s\S]*)/);
            "`;
      } else {
        message = `Plz generate comprehensive results and for the design or visuals don't add anything for this pitch slide: "${text}" and if it is empty then it means its a logo and generate in a way that my code should work automatically on your answer and don't add brackets/braces or any closings quotation marks. Make it simple text and numbers where required so my code should parse your answers without interruption with a good comprehensive answer and respresent reponse by mentioning like "based on data in this slide". Here is my code where you answer will be parsed:"  
            const feedbackMatch = fullResponse.match(/Feedback: ([\s\S]*?)Suggestions:/);
            const suggestionsMatch: fullResponse.match(/Suggestions: ([\s\S]*)/)[1].trim(),
            "
            `;
      }

      try {
        await delay(60000);
        const result = await openai.createChatCompletion({
          model: "gpt-4-turbo",
          temperature: 0.75,
          messages: [{ role: "user", content: message }],
        });
        const fullResponse = result.data.choices[0].message.content;

        console.log(fullResponse);
        return index == 0
          ? parseGPTResponseFirst(fullResponse)
          : parseGPTResponseAll(fullResponse);
      } catch (error) {
        console.error("Error in GPT-4 request:", error);
        return await fetchResult(text, index); // Retry on error
      }
    };

    const results = await Promise.all(
      pageTexts.map(async (text, index) => await fetchResult(text, index))
    );

    // setAnalysisResults(results);
    await dispatch(setResponseText2(results));
    // setDeckData(results)
    // Update deck data in the backend
    if (results) {
      setDeckData({
        feedbackMatch: results.map(result => result.feedbackMatch),
        suggestionsMatch: results.map(result => result.suggestionsMatch),
        pitchDeckScoreMatch: results[0]?.pitchDeckScoreMatch,
        deckStructureScoreMatch: results[0]?.deckStructureScoreMatch,
        marketPotentialScoreMatch: results[0]?.marketPotentialScoreMatch,
        businessModelScoreMatch: results[0]?.businessModelScoreMatch,
        deckStructureParagraphMatch: results[0]?.deckStructureParagraphMatch,
        marketPotentialParagraphMatch: results[0]?.marketPotentialParagraphMatch,
        businessModelParagraphMatch: results[0]?.businessModelParagraphMatch,
      })
      setLoading(false);

      updateDeckData({
        feedbackMatch: results.map(result => result.feedbackMatch),
        suggestionsMatch: results.map(result => result.suggestionsMatch),
        pitchDeckScoreMatch: results[0]?.pitchDeckScoreMatch,
        deckStructureScoreMatch: results[0]?.deckStructureScoreMatch,
        marketPotentialScoreMatch: results[0]?.marketPotentialScoreMatch,
        businessModelScoreMatch: results[0]?.businessModelScoreMatch,
        deckStructureParagraphMatch: results[0]?.deckStructureParagraphMatch,
        marketPotentialParagraphMatch: results[0]?.marketPotentialParagraphMatch,
        businessModelParagraphMatch: results[0]?.businessModelParagraphMatch,
      });
    }
    localStorage.setItem('deckStatus', 'old')

  };


  const parseGPTResponseFirst = (fullResponse) => {
    return {
      feedbackMatch: fullResponse
        .match(/Feedback: ([\s\S]*?)Suggestions:/)[1]
        .trim(),
      suggestionsMatch: fullResponse
        .match(/Suggestions: ([\s\S]*?)Pitch Deck score:/)[1]
        .trim(),
      pitchDeckScoreMatch: fullResponse
        .match(
          /Pitch Deck score: ([\s\S]*?)Deck structure score out of 100:/
        )[1]
        .trim(),
      deckStructureScoreMatch: fullResponse
        .match(
          /Deck structure score out of 100: ([\s\S]*?)Market Potential score out of 100:/
        )[1]
        .trim(),
      marketPotentialScoreMatch: fullResponse
        .match(
          /Market Potential score out of 100: ([\s\S]*?)Business Model score out of 100:/
        )[1]
        .trim(),
      businessModelScoreMatch: fullResponse
        .match(/Business Model score out of 100: (\d+)/)[1]
        .trim(),
      deckStructureParagraphMatch: fullResponse
        .match(
          /Deck structure Paragraph: ([\s\S]*?)Market Potential Paragraph:/
        )[1]
        .trim(),
      marketPotentialParagraphMatch: fullResponse
        .match(
          /Market Potential Paragraph: ([\s\S]*?)Business Model Paragraph:/
        )[1]
        .trim(),
      businessModelParagraphMatch: fullResponse
        .match(/Business Model Paragraph: ([\s\S]*)/)[1]
        .trim(),
    };
  };

  const parseGPTResponseAll = (fullResponse) => {
    return {
      feedbackMatch: fullResponse
        .match(/Feedback: ([\s\S]*?)Suggestions:/)[1]
        .trim(),
      suggestionsMatch: fullResponse.match(/Suggestions: ([\s\S]*)/)[1].trim(),
    };
  };
  // -
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setIsDocumentLoaded(true); // Document is loaded, ready to render Carousel
  // }

  const handleCarouselChange = (index) => {
    setCarouselIndex(index);
  };
  // console.log(CarouselIndex);
  const SummaryModal = ({ open, handleClose }) => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="summary-modal-title"
        aria-describedby="summary-modal-description"
        className="element"
        style={{ marginTop: "-4rem" }}
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 26,
            }}
          >
            <CloseIcon style={{ fontWeight: "600", color: "black" }} />
          </IconButton>
          <h2 style={{ marginTop: "-0.1rem" }}>Summary</h2>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "2rem", marginBottom: '5px'
            }}
          >
            <img
              src="/icons/ss-1.png"
              style={{ height: "28px", width: "28px", verticalAlign: "middle" }}
            />
            <h3 style={{ margin: 0 }}>Deck Structure</h3>
          </div>
          <p style={{ maxWidth: "400px", marginBottom: '15px', fontSize: '15px' }}>
            {deckData?.deckStructureParagraphMatch}
          </p>

          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: '5px' }}>
            <img
              src="/icons/ss-2.png"
              style={{ height: "28px", width: "28px", verticalAlign: "middle" }}
            />
            <h3 style={{ margin: 0 }}>Market Potential</h3>
          </div>
          <p style={{ maxWidth: "400px", marginBottom: '15px', fontSize: '15px' }}>
            {deckData?.marketPotentialParagraphMatch}
          </p>

          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: '5px' }}>
            <img
              src="/icons/ss-3.png"
              style={{ height: "28px", width: "28px", verticalAlign: "middle" }}
            />
            <h3 style={{ margin: 0 }}>Business Model</h3>
          </div>

          <p style={{ maxWidth: "400px", marginBottom: '15px', fontSize: '15px' }}>

            {deckData?.businessModelParagraphMatch}

          </p>
        </Box>
      </Modal>
    );
  };

  const [numPages, setNumPages] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [pageIndex, setpageIndex] = useState(0);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    // renderAllPagesAsImages();
    setIsDocumentLoaded(true);
  };

  const renderAllPagesAsImages = async () => {
    setisImagesLoaded(false);
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      const pageImage = await getPageAsImage(s3FileUrl, i);
      pages.push(URL.createObjectURL(pageImage));
    }
    setImageUrls(pages);
    setisImagesLoaded(true);
  };

  const getPageAsImage = async (url, pageNumber) => {
    const page = await pdfjs.getDocument(url).promise.then(pdf => {
      return pdf.getPage(pageNumber);
    });

    const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale as needed
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };

    await page.render(renderContext).promise;
    const imageData = canvas.toDataURL('image/png');
    return fetch(imageData).then(res => res.blob());
  };

  useEffect(() => {
    renderAllPagesAsImages();
  }, [isDocumentLoaded]);

  useEffect(() => {
    setTimeout(() => {
      setscreenReady(true);
    }, 2000);
  }, [])

  let deckStructureScore = parseFloat(deckData?.deckStructureScoreMatch);
  let marketPotentialScore = parseFloat(deckData?.marketPotentialScoreMatch);
  let businessModelScore = parseFloat(deckData?.businessModelScoreMatch);

  let totalScore = (deckStructureScore * 0.2) + (marketPotentialScore * 0.4) + (businessModelScore * 0.4);
  totalScore = +(totalScore.toFixed(2));

  // totalScore will be the calculated value to two decimal places


  return (
    <div className={Classes.container} >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <LoadingLineWithPercentage />
        </div>
      ) : (
        <>
          {screenReady &&
            <>
              <MobileVersionPopup />
              <div className={Classes.innerCon} >
                <div className={Classes.leftCon} >
                  <div className={Classes.listCon} >
                    <div className={Classes.scroll1} >

                      <div className={Classes.logoCon} >
                        <img className={Classes.logo1} onClick={goToDashboard} src={onBoarding.logo} />
                        <img className={Classes.logo2} onClick={goToDashboard} src='/onBoarding/left-arrow.svg' />
                      </div>
                      {numPages && !imageUrls.length ?
                        <>
                          {[...Array(10)].map((val, ind) => (
                            <div key={ind} className={Classes.listBox}>
                              <div className={Classes.boxImgCon}>
                                <img src='/icons/mini-sample.png' alt="Icon" />
                              </div>
                              <div className={Classes.skTxt} ></div>
                            </div>
                          ))
                          }
                        </>
                        :
                        <>
                          {imageUrls && imageUrls.map((imageUrl, index) => {
                            return (<>
                              <PageSelctorComp imageUrl={imageUrl} key={index} index={index} pageIndex={pageIndex} setpageIndex={setpageIndex} />
                            </>)
                          })
                          }
                        </>
                      }

                    </div>
                  </div>
                </div>
                <div className={Classes.con1} >
                  <div className={Classes.swiperOuter} >
                    <div className={Classes.swiperCon} >

                      {isImagesLoaded ?
                        <>
                          {numPages && !imageUrls.length ?
                            <>
                              <img src={'/icons/big-sample-image.png'} className={Classes.swiperImg} alt={`Sample Page`} />
                            </>
                            :
                            <img src={imageUrls[pageIndex]} className={Classes.swiperImg} alt={`Page ${pageIndex + 1}`} />
                          }
                        </>
                        :
                        <div className={Classes.swiperUnloadedCon} >
                          <img src={'/icons/big-sample-image.png'} className={Classes.swiperImg} alt={`Sample Page 2`} />
                          <h5 className={Classes.loadingDocTxt} > Loading document... </h5>
                        </div>
                      }

                      <Document
                        className={Classes.reactPdf}
                        file={s3FileUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{
                          cMapUrl: 'cmaps/',
                          cMapPacked: true,
                        }}
                      ></Document>

                    </div>
                  </div>

                  {deckData ? (
                    <div className={Classes.boxesCon} >

                      <div className={Classes.feedbackCon} >
                        <div >
                          <img src="/feedback.png" />
                          <h3> Feedback </h3>
                        </div>
                        <p>
                          <pre style={{
                            ...styles,
                            flex: "1", // Allow the content area to expand
                            minWidth: "0", // Prevent the content from causing element collapse
                            margin: '0',
                            // paddingTop: "0.22rem",
                            // marginTop: '8px',
                          }}>
                            {
                              deckData?.feedbackMatch?.[pageIndex] !== undefined ?
                                deckData?.feedbackMatch[pageIndex] :
                                null
                            }

                          </pre>
                        </p>

                      </div>

                      <div className={Classes.suggestCon} >
                        <div >
                          <img src="/icons/suggestion.png" />
                          <h3> Suggestions </h3>
                        </div>
                        <p>
                          <pre style={{
                            ...styles,
                            flex: "1", // Allow the content area to expand
                            minWidth: "0", // Prevent the content from causing element collapse
                            margin: '0',
                            // paddingTop: "0.22rem",
                            // marginTop: '8px',
                          }}>

                            {
                              deckData?.suggestionsMatch?.[pageIndex] !== undefined ?
                                deckData?.suggestionsMatch[pageIndex] : null
                            }
                          </pre>
                        </p>
                      </div>

                    </div>
                  ) : null}
                </div>

                <div className={Classes.scoreBoxCon} >
                  {deckData ? (
                    <div className={Classes.scoreBox} >
                      <h3 className="fw700" >Scoring breakdown</h3>
                      <h5 onClick={() => { setopenSummary(true) }} style={{ color: onBoarding.color }} >Read full summary {`>`}</h5>
                      {/* <SummaryModal open={open} handleClose={handleClose} /> */}
                      {openSummary &&
                        <SummaryPopup
                          style={style}
                          openSummary={openSummary}
                          handleCloseSummary={handleCloseSummary}
                          deckData={deckData}
                        />
                      }
                      <div className={Classes.padCon} >
                        <hr />
                        <div className={Classes.linesCon} >
                          <div className={Classes.scoringGrid} >
                            <div className={Classes.flex1} >
                              <img src="/deck.png" alt="Image" />
                              <h4 className={Classes.miniTitle} >Deck structure <span className={Classes.miniPerc} ></span> </h4>
                            </div>
                            <h4 className={Classes.miniTitle} > {deckData?.deckStructureScoreMatch ?? 0} </h4>
                          </div>
                          <div className={Classes.scoringGrid} >
                            <div className={Classes.flex1} >
                              <img src="/market.png" alt="Image" />
                              <h4 className={Classes.miniTitle} >Market opportunity <span className={Classes.miniPerc} ></span> </h4>
                            </div>
                            <h4 className={Classes.miniTitle}> {deckData?.marketPotentialScoreMatch ?? 0} </h4>
                          </div>
                          <div className={Classes.scoringGrid} >
                            <div className={Classes.flex1} >
                              <img src="/onBoarding/marketing.png" alt="Image" />
                              <h4 className={Classes.miniTitle} >Marketing & Sales <span className={Classes.miniPerc} ></span> </h4>
                            </div>
                            <h4 className={Classes.miniTitle} > {deckData?.businessModelScoreMatch ?? 0} </h4>
                          </div>
                          <div className={Classes.scoringGrid} >
                            <div className={Classes.flex1} >
                              <img src="/business.png" alt="Image" />
                              <h4 className={Classes.miniTitle} >Business model <span className={Classes.miniPerc} ></span> </h4>
                            </div>
                            <h4 className={Classes.miniTitle} > {deckData?.businessModelScoreMatch ?? 0} </h4>
                          </div>
                          <div className={Classes.scoringGrid} >
                            <div className={Classes.flex1} >
                              <img src="/onBoarding/competitive.png" alt="Image" />
                              <h4 className={Classes.miniTitle} > Competitive landscape <span className={Classes.miniPerc} ></span> </h4>
                            </div>
                            <h4 className={Classes.miniTitle} > {deckData?.businessModelScoreMatch ?? 0} </h4>
                          </div>
                          <div className={Classes.scoringGrid} >
                            <div className={Classes.flex1} >
                              <img src="/onBoarding/traction.png" alt="Image" />
                              <h4 className={Classes.miniTitle} > Traction <span className={Classes.miniPerc} ></span> </h4>
                            </div>
                            <h4 className={Classes.miniTitle} > {deckData?.businessModelScoreMatch ?? 0} </h4>
                          </div>
                        </div>
                      </div>
                      <div className={`${Classes.overallCon} ${Classes.padCon}`} style={{ background: onBoarding.color }} >
                        <h4 className={Classes.miniTitle} > Overall deck score </h4>
                        <h4 className={Classes.miniTitle} >
                          {totalScore}
                        </h4>
                      </div>
                      <div className={`${Classes.templateOuter} ${Classes.padCon} `} >
                        <div className={Classes.templateCon} style={{ background: convertToRgba(onBoarding.color, 0.05) }} >
                          {/* <img src="/template.png" alt="Image" /> */}
                          <div className={Classes.ideaImg} style={{ background: onBoarding.color }} >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 384 512"><path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z" /></svg>
                          </div>
                          <h4>Pitch deck template</h4>
                          <h5>
                            Download our ultimate pitch <br />
                            deck template with over 125 <br />
                            premium designed slides.
                          </h5>
                          <h4 className={Classes.download} onClick={handleGoToTemplate} style={{ color: onBoarding.color }} >Download here</h4>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          }
        </>
      )
      }
    </div >
  );
}

export default Testing1;
