import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SectionA from './Components/SectionA';
import SectionB from './Components/SectionB';
import SectionC from './Components/SectionC';
import SectionD from './Components/SectionD';
import PricingAndFaqs from './Components/PricingAndFaqs';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import sessionstorage from "sessionstorage";
import SectionE from './Components/SectionE';
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux-store';

function HomeNew() {
  const [width, setWidth] = useState(window.innerWidth);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const navigateTo = useNavigate();

  const handleLogout = async () => {
    localStorage.clear();
    sessionstorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    // alert("Signed out successfully")
  };

  useEffect(() => {
    if (user) {
      handleLogout();
    }
  }, [user]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  
  const scrollToElement = (elementId) => {
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      const offset = 0;
      const targetPosition = targetElement.offsetTop + offset;

      window.scroll({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };
  
  useEffect(()=>{
    const navScroll = localStorage.getItem('navScroll');
    if(navScroll){
      scrollToElement(navScroll);
      localStorage.removeItem('navScroll');
    }
  },[])


  return (
    <div>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <PricingAndFaqs />
    </div>
  );
}

export default HomeNew;
