import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Configuration, OpenAIApi } from "openai";
import { Helmet } from "react-helmet";
import CircleIcon from '@mui/icons-material/Circle';
import BingSearch from "./BingApi";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {  ToastContainer,toast } from "react-toastify";
import PptxGenJS from "pptxgenjs";
import Button from '@mui/material/Button';


const ChatContainer = styled.div`
  width: 600px;
  height: 500px;
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
`;

const ChatHeader = styled.div`
  padding: 10px;
  background-color: #dcdcdc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  font-family: "Montserrat", sans-serif;
`;

const ChatContent = styled.div`
  height: calc(100% - 170px);
  padding: 16px;
  overflow-y: auto;
`;

const ChatMessage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

const ChatBubble = styled.div`
  max-width: 80%;
  padding: 8px;
  border-radius: 8px;
  background-color: ${(props) => (props.isUser ? "rgba(0, 147, 255, 1)" : "rgb(237, 231, 246)")};
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
//   padding: 5px;
  background: 1px solid gray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ChatInput = styled.input`
  flex: 1;
  height: 25px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;

`;

const ChatButton = styled.button`
  margin-left: 16px;
  padding: 8px 16px;
  background-color: #333333;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function PitchDeckGen() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [image, setImage] = useState();
  const [fileData, setFileData] = useState('');
  const [resp, setResp] = useState('');
  const [slides, setSlides] = useState([]); // Hold slide images
  // console.log(messages);
  

  const PowerPointGenerator = ({ response }) => {
    const generatePowerPoint = () => {
      let pptx = new PptxGenJS();
      const maxCharactersPerSlide = 500; // Adjust as needed
      const contentParts = splitContent(response, maxCharactersPerSlide);
      let newSlides = []; // This will hold data for new slides

      contentParts.forEach((part, index) => {
        let slide = pptx.addSlide();
        slide.addText(`Slide ${index + 1}`, { x: 0.5, y: 0.5, fontSize: 24, bold: true });
        slide.addText(part, { x: 0.5, y: 1, fontSize: 18, color: '363636' });
        newSlides.push(slide);
      });
      setSlides(newSlides); // Make sure setSlides is passed down as a prop or managed locally

      pptx.writeFile({ fileName: "Generated-Presentation.pptx" });
    };
  
    // Function to split content into chunks
    const splitContent = (content, maxLength) => {
      const regexPattern = new RegExp('.{1,' + maxLength + '}', 'g'); // Regular expression to split the content
      return content.match(regexPattern) || [];
    };
  
    return resp?(
      <Button variant="contained" color="primary" onClick={generatePowerPoint}>
        Generate PowerPoint
      </Button>
    ):null;
  };

  

  const handleNewSlides = (newSlides) => {
    setSlides(newSlides);
  };

  const addSlides = () => {
    const newSlides = ["bot.png", "bot.png", "bot.png"]; // Replace with actual image URLs
    setSlides(newSlides);
  };


  console.log(slides)

  const handleSearch = () => {
      if(query.trim()=="" || query.trim()==null || query.trim()==undefined) {
        alert("Please enter a search term")
        return;
      }
      setLoading(true);
      const bingApiUrl = 'https://api.bing.microsoft.com/v7.0/search';
      const apiKey = 'fc6422b92ec6420aadeeb80c22ca7b76'; // Replace with your API key

      fetch(bingApiUrl + '?q=' + encodeURIComponent(query), {
          method: 'GET',
          headers: {
              'Ocp-Apim-Subscription-Key': apiKey
          }
      })
      .then(response => response.json())
      .then(data => {
          // setResults(data.webPages ? data.webPages.value : []);
          const snippets = data.webPages ? data.webPages.value.map(item => item.snippet) : [];
          setResults(snippets)
      })
      .catch(error => {
          console.error('Error fetching data: ', error);
      });
  };

  useEffect(() => {
    if(results?.length > 0) {
      handleSendMessage()
    }
  }, [results]);

  console.log(results);
  console.log(messages);
  const text =
    "  Greetings! I am developed by Hamza. Nice to meet you in the digital world. I'm here to give you a virtual hand and address any questions or concerns you may have. My knowledge and skills are at your disposal. So don't hesitate to ask me something. How can I be of service to you today?    ";

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      setDisplayText((prevText) => prevText + text[currentIndex]);
      currentIndex++;
      if (currentIndex === text.length) {
        clearInterval(typingInterval);
      }
    }, 20);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  const chatContentRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = () => {
    setImage();
      const newMessage = {
        content: query.trim(),
        isUser: true,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      sendBotMessage(newMessage.content);
      setInputText("");
      setQuery("");
    
  };

  const configuration = new Configuration({
    apiKey: "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",
  });
  const openai = new OpenAIApi(configuration);


  const sendBotMessage = async (userMessage) => {
    setLoading(true)
    const message = `Please respond based on your previous response if not empty or undefined: "${messages}". Here is my query: "${userMessage}".Check from this live data fetched from bing: ${results} and respond with desired results And if query is not about previous chat data then talk normal about new topic. If query contains or ask about live search internet or browse live then just check my fetched data from bing that I provided and In the response never mention data is from bing. Just write output`;

    try {
      const result = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        temperature: 0.75,
        messages: [{ role: "user", content: message }],
      });


      const botMessage = {
        content: result?.data?.choices[0]?.message?.content,
        isUser: false,
      };

      setResp(result?.data?.choices[0]?.message?.content)
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  



  const copyResponse = async(msg) => {
    await navigator.clipboard.writeText(msg);
    alert("Copied successfully")
  };
  const lastItem = messages[messages.length - 1];
  const lastMessage = "Plz regenerate this answer" + (lastItem?.content || "");

  const styles = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '1.5',
    color: 'black',
    whiteSpace: 'pre-wrap', // allow wrapping of long lines
    wordWrap: 'break-word' // break long words
  };
 
  return (
    <>
   

      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >


      <PowerPointGenerator response={resp} onNewSlides={handleNewSlides} />
      <div>
      {/* <button onClick={addSlides}>Add Slides</button> */}
      {/* <div>
        {slides.length > 0 ? (
          slides.map((slide, index) => (
            <img key={index} src={slide} alt={`Slide ${index + 1}`} />
          ))
        ) : (
          <p>No slides to display.</p>
        )}
      </div> */}
    </div>

      </div>
{/* <BingSearch/> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop:"1.5rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        {/* <ChatContainer> */}

          <ChatContent ref={chatContentRef}>
            
          {messages?.map((message, index) => (
  <div key={index}>
    {!message.isUser ? (
         <ChatMessage key={index} isUser={message.isUser}>
          {message.content?
         <ChatBubble>
           <div style={{ display: "flex", alignItems: "center" }}>
             <p style={{
               background: "rgb(9 70 122)",
               marginRight: "1rem",
               textAlign: "center",
               width: "30px",
               height: "30px",
               fontWeight: "600",
               lineHeight: "30px",
               borderRadius: "50%",
               color: "white",
               boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
               cursor: "pointer",
             }}>AI</p>
             <pre style={{
               ...styles,
               flex: "1", // Allow the content area to expand
               minWidth: "0", // Prevent the content from causing element collapse
             }}>
               {message.content}
             </pre>
           </div>
           <div style={{display:"flex", flexDirection:"row",justifyContent:"flex-end", alignItems:"center"}}>
            <ContentPasteIcon style={{fontSize:"20px", fontWeight:"600"}}/>
            <button
            style={{
                background:"transparent", 
                padding:"0.5rem", 
                // borderRadius:"25px",
                marginRight:"1rem",
                border:"1px solid transparent",
                cursor:"pointer",
                fontSize:"13px"
                }}
                onClick={()=>copyResponse(message.content)}
            >
                Copy
            </button>

           
            <RestartAltIcon style={{fontSize:"20px", fontWeight:"600"}}/>
            <button
            style={{
              background:"transparent", 
              padding:"0.5rem", 
              // borderRadius:"25px",
              // marginRight:"1rem",
              border:"1px solid transparent",
              cursor:"pointer",
              fontSize:"13px"
              }}
                onClick={()=>sendBotMessage(lastMessage)}
            >
                Regenerate
            </button>
        </div>
         </ChatBubble>:null}
       </ChatMessage>
       
        

    ) : null
    }
    <br />
   
  </div>
))}

{image?.length>0? (
  <div>
    <img className="result-image" src={image} alt="image" />
    <a
      href={image}
      target="_blank"
      download="image.png" // You can specify the file name here
    >
      <button>open</button>
    </a>
  </div>
) : null}
          </ChatContent>
          <br/>
          {/* <input 
                type="text" 
                value={query} 
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search with Bing"
            />
            <button onClick={handleSearch}>Search</button> */}
               <p style={{ justifyContent: 'flex-start',  }}>
        {loading ? 
 <div style={{ display: 'flex', alignItems: 'center' }}>
 <img src="/bot.gif" style={{width: '70px',marginLeft:"1rem", height: '70px', borderRadius:"25px"}} />
</div>       
        : null}
      </p> 

          <ChatInputContainer>

              <ChatInput
              type="text"
              value={query}
              style={{height: "300px", width: "500px", border:"2px solid gray"}}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Enter your details"
            />
           

            {/* <ChatInput
              type="text"
              value={inputText}
              onChange={handleInputChange}
              placeholder="Type a message..."
            />
            <ChatButton onClick={handleSendMessage}>Send</ChatButton> */}
          </ChatInputContainer>
        {/* </ChatContainer> */}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
      <ChatButton onClick={handleSearch}>Generate</ChatButton>
           
            
    </div>
     
      <ToastContainer/>
    </>
  );
}

export default PitchDeckGen;
