import React from 'react'
import Classes from './LiveDemo.module.css'
import { useSelector } from 'react-redux';

function PageSelctorComp({ imageUrl, index, pageIndex, setpageIndex }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    
    const handleClick = () => {
        setpageIndex(index);
    }
    return (
        <>
            <div className={Classes.listBox} onClick={handleClick} style={{ border: pageIndex === index ? `2px solid ${onBoarding.color}` : '' }} >
                <div className={Classes.boxImgCon} >
                    <img src={imageUrl} />
                </div>
                <h5> Slide {index + 1} </h5>
            </div>
        </>
    )
}

export default PageSelctorComp