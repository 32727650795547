import React, { useState } from 'react';
import Classes from './SummaryPopup.module.css';
import { useSelector } from 'react-redux';

function SummaryPopup({ openSummary, handleCloseSummary }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const handleBgClick = () => {
        handleCloseSummary();
    };

    const handleCardClick = (event) => {
        event.stopPropagation();
    };

    const [deckData,setDeckData] = useState({
        score: 75,
        companyName: 'Uber',
        industry: 'Tech',
        description: 'This is uber description',
        founder: 'Uber',
        website: 'uber.com',
        email: 'uber@mail.com',
        link: '/uber',
        businessModelScoreMatch: '84',
        businessModelParagraphMatch: `The business model is robust, detailing revenue streams, cost structure, and profitability. It could be enhanced by including more case studies or examples of similar successful business models. Marketing Sales score: 70 Team score: 75 Traction score: 68 Competitive Landscape score: 74 Marketing Sales Paragraph: Sales strategies and marketing plans are broadly outlined but lack detailed execution steps or timelines. Team Paragraph: The team's background is impressive, covering key areas needed for the project. However, the slide could further detail each member's specific roles and contributions. Traction Paragraph: Early traction is demonstrated, but the data provided is not compelling enough. More detailed metrics and growth indicators would be beneficial. Competitive Landscape Paragraph: Competitive analysis covers major competitors and market positioning well. It would be more effective with a clearer differentiation strategy.`,
        deckStructureScoreMatch: '78',
        deckStructureParagraphMatch: 'The overall structure of the pitch deck is clear, with each slide dedicated to a specific topic. However, some slides feel cluttered with too much information.',
        marketPotentialScoreMatch: '85',
        marketPotentialParagraphMatch: 'The market analysis is well-researched, showcasing a deep understanding of market size, growth trends, and customer segments. Yet, it could improve by highlighting more specific data points that support the market demand.',
        marketingSalesScoreMatch: '72',
        marketingSalesParagraphMatch: 'Sales strategies and marketing plans are broadly outlined but lack detailed execution steps or timelines.',
        competitiveLandscapeScoreMatch: '74',
        competitiveLandscapeParagraphMatch: 'Competitive analysis covers major competitors and market positioning well. It would be more effective with a clearer differentiation strategy.',
        teamScoreMatch: '75',
        teamParagraphMatch: `The team's background is impressive, covering key areas needed for the project. However, the slide could further detail each member's specific roles and contributions.`,
        tractionScoreMatch: '68',
        tractionParagraphMatch: 'Early traction is demonstrated, but the data provided is not compelling enough. More detailed metrics and growth indicators would be beneficial.',
    })

    return (
        <>
            <div className={Classes.popup} onClick={handleBgClick}>
                <div className={Classes.card} onClick={handleCardClick}>
                    <div className={Classes.summaryBg}>
                        <div className={Classes.close} onClick={handleCloseSummary}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </div>

                        <div className={Classes.flexBetween}>
                            <h2 className='mb20'>Summary</h2>
                        </div>
                        <div className={Classes.scroll} >
                            <div className={Classes.flexAlign}>
                                <img src='/deck.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Deck Structure</h3>
                            </div>
                            <p className={Classes.para}>
                                { deckData?.deckStructureParagraphMatch }
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/market.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Market Opportunity</h3>
                            </div>
                            <p className={Classes.para}>
                                { deckData?.marketPotentialParagraphMatch }
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/marketing.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Marketing Sales</h3>
                            </div>
                            <p className={Classes.para}>
                                { deckData?.marketingSalesParagraphMatch }
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/business.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>businessModel</h3>
                            </div>
                            <p className={Classes.para}>
                                { deckData?.businessModelParagraphMatch }
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/competitive.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Competitive Landscape</h3>
                            </div>
                            <p className={Classes.para}>
                                { deckData?.competitiveLandscapeParagraphMatch }
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/team.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Team</h3>
                            </div>
                            <p className={Classes.para}>
                                { deckData?.teamParagraphMatch }
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/traction.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Traction</h3>
                            </div>
                            <p className={Classes.para}>
                                { deckData?.tractionParagraphMatch }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SummaryPopup;