// reducers.js
import { SET_EDITOR_TEXT } from './actions';

const initialState = {
  editorText: '',
};

const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITOR_TEXT:
      return {
        ...state,
        editorText: action.payload,
      };
    default:
      return state;
  }
};

export default editorReducer;
