import types from '../types'
import backend from '../../api'



export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';

export const setCompanyInfo = (text) => {
  return {
    type: SET_COMPANY_INFO,
    payload: text,
  };
};



export const SET_DECK_ID = 'SET_DECK_ID';

export const setDeckId = (text) => {
  return {
    type: SET_DECK_ID,
    payload: text,
  };
};


export const SET_SIDEBAR = 'SET_SIDEBAR';

export const setSidebar= (text) => {
  return {
    type: SET_SIDEBAR,
    payload: text,
  };
};


export const SET_LANGUAGE = 'SET_LANGUAGE';

export const setLanguage= (text) => {
  return {
    type: SET_LANGUAGE,
    payload: text,
  };
};


export const SET_STEPPER_VALUE = 'SET_STEPPER_VALUE';

export const setStepperValue= (text) => {
  return {
    type: SET_STEPPER_VALUE,
    payload: text,
  };
};


export const SET_CHATHISTORY_ID = 'SET_CHATHISTORY_ID';

export const setChatHistoryId = (text) => {
  return {
    type: SET_CHATHISTORY_ID,
    payload: text,
  };
};


export const SET_EDITOR_TEXT = 'SET_EDITOR_TEXT';

export const setEditorText = (text) => {
  return {
    type: SET_EDITOR_TEXT,
    payload: text,
  };
};


export const SET_RESPONSE_TEXT = 'SET_RESPONSE_TEXT';

export const setResponseText = (text) => {
  return {
    type: SET_RESPONSE_TEXT,
    payload: text,
  };
};

export const SET_RESPONSE_TEXT2 = 'SET_RESPONSE_TEXT2';

export const setResponseText2 = (text) => {
  return {
    type: SET_RESPONSE_TEXT2,
    payload: text,
  };
};

export const SET_PROMPT_TEXT = 'SET_PROMPT_TEXT';

export const setPromptText = (text) => {
  return {
    type: SET_PROMPT_TEXT,
    payload: text,
  };
};



export const setUserData = (payload) => {
  return {
    type: types.SET_USER_DATA,
    payload,
  }
}

export const setAuthLoading = (payload) => {
  return {
    type: types.SET_AUTH_LOADING,
    payload,
  }
}

export const setAuthErrors = (payload) => {
  return {
    type: types.SET_AUTH_ERRORS,
    payload,
  }
}
export const setLoginErrors = (payload) => {
  return {
    type: types.SET_LOGIN_ERRORS,
    payload,
  }
}
export const setRegisterErrors = (payload) => {
  return {
    type: types.SET_REGISTER_ERRORS,
    payload,
  }
}
export const setPasswordChangeSuccess = (payload) => {
  return {
    type: types.SET_PASSWORD_CHANGE_SUCCESS,
    payload,
  }
}

export const setProfileExists = (payload) => {
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  if (user) {
    user.profileExists = payload
    localStorage.setItem('user', JSON.stringify(user))
  }
  return {
    type: types.SET_PROFILE_EXISTS,
    payload,
  }
}

export const login = (email, password) => async (dispatch) => {

  try {
    dispatch(setAuthLoading(true))
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await backend.post('/api/user/login', { email, password }, config)
    await dispatch(
      setUserData({
        ...data.data,
        token: data.token,
        profileExists: data.exists,
      })
    )
    localStorage.setItem(
      'user',
      JSON.stringify({...data.data})
    )
    return true;
  } catch (error) {
    dispatch(
      setLoginErrors(
        error?.response ? error.response.data.error : error.message
      )
    )
    return false;
  }
}

export const signUp = (email, password, fullname, role) => async (dispatch) => {
  try {
    dispatch(setAuthLoading(true))
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
 
    const { data } = await backend.post(
      `/api/user/admin/signup`,
      { email, password, fullname, role},
      config
    )

    await dispatch(
      setUserData({
        ...data.data,
        token: data.token,
        profileExists: data.exists,
      })
    )

    localStorage.setItem(
      'user',
      JSON.stringify({
        ...data.data,
        token: data.token,
        profileExists: data.exists,
      })
    )
    return data;
  } catch (error) {
    dispatch(setRegisterErrors(error?.response?.data.error))
    return false;
  }
}

export const logout = () => {
  localStorage.removeItem('user')
  return {
    type: types.USER_LOGOUT,
  }
  
}


export const forgotPassword =
  (email, resetLink) => async (dispatch) => {
    try {
      dispatch(setAuthLoading(true))
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      await backend.put(
        `/api/user/password/forgetpassword`,
        { email,resetLink },
        config
      )
      dispatch(setPasswordChangeSuccess(true))

      setTimeout(() => dispatch(setPasswordChangeSuccess(false)), 5000)
      return true;
    } 
    catch (error) {
      dispatch(setAuthErrors(error.response.data.error))
      return false;
    }
  }

export const changePassword =
  (user_id, current_pass, new_pass) => async (dispatch) => {
    try {
      dispatch(setAuthLoading(true))
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      await backend.put(
        `/${user_id}/password/change`,
        { current_pass, new_pass },
        config
      )
      dispatch(setPasswordChangeSuccess(true))

      setTimeout(() => dispatch(setPasswordChangeSuccess(false)), 5000)
    } catch (error) {
      dispatch(setAuthErrors(error.response.data.error))
    }
  }
