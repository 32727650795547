import LogoutIcon from "@mui/icons-material/Logout";
import React, { useEffect, useState } from "react";
import { LinearProgress, Paper, Button, Modal } from '@mui/material';
import StructureIcon from '@mui/icons-material/AccountBalance';
import MarketIcon from '@mui/icons-material/ShowChart';
import BusinessIcon from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { authActions } from "../redux-store";
import Typography from "@mui/material/Typography";
import TelegramIcon from "@mui/icons-material/Telegram";
import NotesIcon from "@mui/icons-material/Notes";
import FolderIcon from "@mui/icons-material/Folder";
import backend from "../api";
import HistoryIcon from "@mui/icons-material/History";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import sessionstorage from "sessionstorage";
import { setChatHistoryId } from "../redux-store/auth/actions";
import { setSidebar } from "../redux-store/auth/actions";
import DashboardIcon from '@mui/icons-material/Dashboard';
import './Sidebar.css'
import Classes from './Sidebar.module.css'
import UpgradePopup from "./UpgradePopup/UpgradePopup";
import { setCompanyInfo } from "../redux-store/auth/actions";
import Color from 'color';

const drawerWidth = 265;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto', // Adjust width as needed
  maxWidth: '70vw', // Maximum width of the modal is 80% of the viewport width
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto', // Enable scrolling if content is too long
  maxHeight: '90vh', // Maximum height is 80% of the viewport height
  margin: '5vh', // Adds margin to the top & bottom of the modal (5% of the viewport height)
  borderRadius: "15px",

};

// The rest of your components remain unchanged

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navBtn = sessionstorage.getItem("navBtn")
  const [selectedButton, setSelectedButton] = React.useState(navBtn ? navBtn : "Pitch Deck Dashboard");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const userID = user?._id
  console.log(user);
  const [loading, setLoading] = React.useState(false);
  const BotID = localStorage.getItem('projectID');
  const ProjectName = localStorage.getItem('projectName');
  const ProjectID = localStorage.getItem("projectID")

  const [pitchDeckScore, setPitchDeckScore] = useState('');
  const [deckStructureScore, setDeckStructureScore] = useState('');
  const [marketPotentialScore, setMarketPotentialScore] = useState('');
  const [businessModelScore, setBusinessModelScore] = useState('');

  const [deckStructureParagraph, setDeckStructureParagraph] = useState('');
  const [marketPotentialParagraph, setMarketPotentialParagraph] = useState('');
  const [businessModelParagraph, setBusinessModelParagraph] = useState('');
  const sideBarValue = useSelector((state) => state.sidebar.sidebarValue);
  const [companyDetails, setCompanyDetails] = useState([]);

  const deckId = useSelector((state) => state.deckId.deckId);
  const companyInfo = useSelector((state) => state.companyInfo?.info);


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpgradePopup, setopenUpgradePopup] = useState(false);
  const closeUpgradePopup = () => {
    setopenUpgradePopup(false);
  }

  const navigateTo = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    // Define the async function inside useEffect
    const fetchCompanyDetails = async () => {
      try {
        setLoading(true); // Set loading to true at the beginning of the request
        const res = await backend.get(`/onboarding?userID=${userID}`);
        // console.log(res?.data[0])
        if(res?.data[0]) {
          await dispatch(setCompanyInfo(res?.data[0]))
        }
         // setFilteredFiles(res?.data);
      } catch (error) {
        console.error("Failed to company data:", error);
        // Handle error state as needed
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    // Call the async function
    fetchCompanyDetails();
  }, []);
  console.log(companyInfo)


  const NavigateToBots = async () => {
    sessionstorage.setItem("navBtn", "")
    const id = localStorage.getItem('projectID');
    await navigateTo(`/chatbots/${id}`)
  };

  console.log(sideBarValue)
  //  useEffect(() => {


  //   // Update component state based on sideBarValue
  //   if (sideBarValue) {
  //     // Adjust these according to the actual structure of sideBarValue
  //     setPitchDeckScore(sideBarValue?.pitchDeckScore);
  //     setDeckStructureScore(sideBarValue?.deckStructureScore);
  //     setMarketPotentialScore(sideBarValue?.marketPotentialScore);
  //     setBusinessModelScore(sideBarValue?.businessModelScore);
  //     setDeckStructureParagraph(sideBarValue?.deckStructureParagraph);
  //     setMarketPotentialParagraph(sideBarValue?.marketPotentialParagraph);
  //     setBusinessModelParagraph(sideBarValue?.businessModelParagraph);
  //   }


  // }, [sideBarValue]); // Re-run when sideBarValue changes


  const saveChat = async () => {
    const userId = user._id

    try {
      const body = {
        UserId: userId,
        projectId: ProjectID
      };

      const response = await backend.post("/chats", body);
      // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      dispatch(setChatHistoryId(RouteID))
      await UpdateProjectById(RouteID)
      // navigateTo(`/chat/${ProjectID}/${RouteID}`)

    } catch (error) {
      console.error(error);
      // toast.error("Error saving story");
    }

  };

  const UpdateProjectById = async (newChatData) => {

    console.log(newChatData)

    const id = await user?._id;
    try {
      // Fetch the existing project data
      setLoading(true);
      const response = await backend.get(`/mainfolder/${ProjectID}`);
      console.log(response?.data)
      const existingProjectData = response?.data;

      // Modify the chats array by merging new data with the existing data
      const updatedChats = [...existingProjectData.chats, newChatData];

      // Prepare the updated project body
      const body = {
        ...existingProjectData,
        chats: updatedChats,
      };
      // console.log(typeof(ProjectID))
      // Make the PUT request to update the project
      const resp = await backend.put(`/mainfolder/${ProjectID}`, body);

      console.log(resp)
      setLoading(false);
      // Optionally, you can return the updated project data
      navigateTo(`/chat/${ProjectID}/${newChatData}`)
    } catch (error) {
      // Handle error
      console.error("Error updating project:", error.message);
      throw error;
    }
  };

  const s3FileUrl = localStorage.getItem('pitch');


  useEffect(() => {
    if (selectedButton === 'Business Chatbot') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/chatbot`)
    }
    if (selectedButton === 'Pitch Deck Analyzer') {
      if (s3FileUrl) {
        sessionstorage.setItem("navBtn", selectedButton)

        dispatch(setChatHistoryId(''))
        // navigateTo(`/analyzer`)
        navigateTo(`/analyzer/${deckId}`)
      } else {
        sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")
        setSelectedButton("Pitch Deck Dashboard")

        dispatch(setChatHistoryId(''))
        navigateTo(`/dashboard`)
      }

    }
    if (selectedButton === 'Chat') {
      // navigateTo('/chat')
      sessionstorage.setItem("navBtn", selectedButton)
      saveChat();
    }

    if (selectedButton === 'Pitch Deck Editor') {
      sessionstorage.setItem("navBtn", selectedButton)
      dispatch(setChatHistoryId(''))
      navigateTo(`/pitcheditor/`)
    }
    if (selectedButton === 'Pitch Deck Dashboard') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/dashboard`)
    }
    if (selectedButton === 'Screening Dashboard') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/screening-dashboard`)
    }
    if (selectedButton === 'Setting') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/setting`)
    }
    if (selectedButton === 'Pitch Deck Templates') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/templates/`)
    }
    if (selectedButton === 'Testing') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/testing/`)
    }
    if (selectedButton === 'Testing-1') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/testing1/`)
    }


  }, [selectedButton])

  const handleLogout = async () => {
    localStorage.clear();
    sessionstorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    // alert("Signed out successfully")
  };

  const handleSettingClick = () => {
    setSelectedButton('Setting');
    navigateTo('/setting');
  }

  const [onBoarding, setonBoarding] = useState({
    companyName: "e-Fest",
    companyWebsite: "efest.pitchScreen.ai",
    color: "#ED3A23",
    logo: "/onBoarding/efest.png",
  });

  const convertToRgba = (colorInput, opacity) => {
    try {
      const color = Color(colorInput);
      return color.alpha(opacity).rgb().string();
    } catch (error) {
      console.error("Invalid color format:", colorInput);
      return `rgba(0, 0, 0, ${opacity})`;
    }
  };

  const handleHomeClick = async () => {
    sessionstorage.setItem("navBtn", 'Pitch Deck Dashboard')
    sessionstorage.setItem("refresh", 'yes')
    navigateTo("/");
    // alert("Signed out successfully")
  };

  const ProgressWithLabel = ({ value, label }) => (
    <Box display="flex" alignItems="center">
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{label}</Typography>
      </Box>
      <Box width="100%" mx={2}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );

  const ScoreProgress = ({ value, IconComponent, label }) => {
    const numericValue = parseFloat(value); // Convert string value to number

    // Determine color based on value
    let color;
    if (numericValue >= 100) {
      color = 'green'; // Strong
    } else if (numericValue >= 80) {
      color = 'lightgreen'; // Low strong
    } else {
      color = '#F44336'; // Weak or normal
    }

    // Determine the color of the LinearProgress based on the score
    const progressColor = (value) => {
      if (value >= 100) return 'green'; // Strong
      else if (value >= 80) return 'lightgreen'; // Low strong
      else return '#F44336'; // Weak or normal
    };

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <img src={`/icons/${IconComponent}`} style={{ width: "23px", height: "23px", paddingRight: "1rem" }} />
          <p style={{ color: "black", fontWeight: "600" }}>{label}</p>
        </div>
        <Box display="flex" alignItems="center" >
          <Box width="100%" mr={1} >
            <LinearProgress
              variant="determinate"
              value={numericValue}
              sx={{
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: progressColor(numericValue),
                },
                backgroundColor: '#eee', // Background color of the progress bar
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(numericValue)}/100`}</Typography>
          </Box>
        </Box>
      </div>
    );
  };
  const drawer = (
    <div style={{
      background: "white",
      // color: "rgba(255, 255, 255, 0.6)",
      minHeight: "100vh",
      // width: "360px", // Set a fixed width for the sidebar
    }}
      className="element"
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <a className={Classes.logo} onClick={() => handleHomeClick()}>
          <img src={companyInfo?.logo} alt="logo" />
        </a>
      </Toolbar>

      <div className="li-items545" >
        <List>
          {[
            "Screening Dashboard",
            "Pitch Deck Dashboard",
          ].map((text, index) => (
            <ListItem key={text} style={{
              width: "100%", background:
                selectedButton === text
                  ? convertToRgba(companyInfo?.color, 0.10)
                  : "transparent",
              borderRight: selectedButton === text ? `6px solid ${companyInfo?.color}` : "",
              // height:"50px",
              padding: "0.45rem 0.5rem"

            }}>
              <ListItemButton
                onClick={() => { index === 2 && !s3FileUrl ? setSelectedButton("Pitch Deck Dashboard") : setSelectedButton(text) }}
                style={{
                  background: "transparent",
                  color: "black",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "0rem 1rem"
                }}
                disabled={index === 10 && !s3FileUrl ? true : false}
                disableRipple // Disables the ripple effect
                disableTouchRipple // Disables the ripple effect on touch devices
              >
                <ListItemIcon style={{ padding: "0rem 0rem" }}>
                  {
                    index === 0 ?
                      (
                        <svg fill={selectedButton === text ? companyInfo?.color : 'black'} height="17px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                        </svg>
                      )
                      :
                      index === 1 ?
                        (
                          <svg fill={selectedButton === text ? companyInfo?.color : 'black'} height="17px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M384 96V224H256V96H384zm0 192V416H256V288H384zM192 224H64V96H192V224zM64 288H192V416H64V288zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
                          </svg>
                        )
                        :
                        (
                          <FolderSpecialIcon />
                        )}
                </ListItemIcon>
                {/* <Typography
                  style={{
                    fontWeight: selectedButton === text ? "550" : "400",
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    color: selectedButton === text ?"#0262DF":'black',
                  }}
                >
                  {text} {index === 3||index===4?<img src="/icons/crown.png" style={{width:"14px", height:"14px", paddingLeft:"0.5rem"}}/>:null}
                </Typography> */}
                <Typography
                  style={{
                    fontWeight: selectedButton === text ? "500" : "500",
                    fontSize: "14px",
                    fontFamily: "Poppins, sans-serif",
                    color: selectedButton === text ? companyInfo?.color : 'black',
                    marginLeft: "-1.8rem"
                  }}
                >
                  {text}
                </Typography>
              </ListItemButton>

            </ListItem>
          ))}

        </List>
        <br />
      </div>
      <div style={{ paddingBottom: "1rem", position: "absolute", bottom: 0, width: '100%' }}>
        {openUpgradePopup && <UpgradePopup closeUpgradePopup={closeUpgradePopup} closeable={true} />}

        {
          user.subscription === 'free' ?
            <div className={Classes.premiumBox} style={{ background: convertToRgba(companyInfo?.color, 0.10) }} >
              <h5 className={Classes.fw700} >0/5 decks uploaded</h5>
              <div className={Classes.progressLine} >

              </div>
              <h5>
                Book a demo to discuss <br />
                enterprise upload limits
              </h5>
              <div onClick={() => { setopenUpgradePopup(true) }} className={Classes.premiumBtn} style={{ background: companyInfo?.color }} >
                <span>Book demo</span>
              </div>
            </div> : null
        }

        <div>
          <Divider style={{ margin: '0 9px 0 9px', marginBottom: "1rem" }} />
          <div className="li-items545" >
            <List>
              <ListItem style={{
                width: "100%", background:
                  selectedButton === 'setting'
                    ? convertToRgba(companyInfo?.color, 0.10)
                    : "transparent",
                borderRight: selectedButton === 'setting' ? `6px solid ${companyInfo?.color}` : "",
                // height:"50px",
                padding: "0.45rem 0.5rem"
              }}
                onClick={handleSettingClick}
              >
                <ListItemButton
                  style={{
                    background: "transparent",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 1rem"
                  }}
                  disableRipple // Disables the ripple effect
                  disableTouchRipple // Disables the ripple effect on touch devices
                >
                  <ListItemIcon style={{ padding: "0rem 0rem" }}>
                    <svg fill={selectedButton === 'setting' ? companyInfo?.color : 'black'} height="17px" xmlns="http://www.w3.org/2000/svg" cursor="pointer" viewBox="0 0 512 512">
                      <path fill="#000000" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: selectedButton === 'setting' ? "500" : "500",
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                      color: selectedButton === 'setting' ? companyInfo?.color : 'black',
                      marginLeft: "-1.8rem"
                    }}
                  >
                    Setting
                  </Typography>
                </ListItemButton>

              </ListItem>
              <ListItem style={{
                width: "100%",
                padding: "0.45rem 0.5rem"
              }}
                onClick={() => { navigateTo('/contact-us') }}
              >
                <ListItemButton
                  style={{
                    background: "transparent",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 1rem"
                  }}
                  disableRipple
                  disableTouchRipple
                >
                  <ListItemIcon style={{ padding: "0rem 0rem" }}>
                    <svg fill='black' height="17px" cursor="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                      color: 'black',
                      marginLeft: "-1.8rem"
                    }}
                  >
                    Support
                  </Typography>
                </ListItemButton>

              </ListItem>
              <ListItem style={{
                width: "100%",
                padding: "0.45rem 0.5rem"
              }}
                onClick={() => handleLogout()}
              >
                <ListItemButton
                  style={{
                    background: "transparent",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 1rem"
                  }}
                  disableRipple // Disables the ripple effect
                  disableTouchRipple // Disables the ripple effect on touch devices
                >
                  <ListItemIcon style={{ padding: "0rem 0rem" }}>
                    <svg fill='black' height="17px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="#000000" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    style={{
                      // fontWeight: selectedButton === 'setting' ? "500" : "500",
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                      color: 'black',
                      marginLeft: "-1.8rem"
                    }}
                  >
                    Logout
                  </Typography>
                </ListItemButton>

              </ListItem>
            </List>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }} className="element">
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ background: "transparent", color: "black", height: "0px" }}
      >
        <Divider />
        <Toolbar sx={{ mr: 2, display: { sm: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        style={{
          boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.07)", // Adding box shadow to the right
          borderRight: '0px solid transparent',
          border: 'none',
          minHeight: "100vh",
          zIndex: '5',
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar  />
        <Dashboard />
      </Box> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          // pt: selectedButton === 'Business Chatbot' ? 0 : 5,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <Toolbar />
      <Dashboard />
      <MenuFooter /> */}
        {/* {
        props.component
      } */}

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "100vh",
              alignItems: "center",
            }}
          >
            {/* <CircularProgress /> */}
            {/* <img src="/loading-2.gif" alt="gif" /> */}
            <div id="loading-bar-spinner" class="spinner">
              <div class="spinner-icon" style={{ borderTopColor: companyInfo?.color, borderLeftColor: companyInfo?.color }}></div>
            </div>
          </div>
        ) : (
          props.component
        )}
      </Box>
      {/* Include your MenuFooter component here */}
    </Box>

  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
